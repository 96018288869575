import { OptionTable } from './option_table';
import { TabulatorFull as Tabulator } from 'tabulator-tables';
import { rpc } from '../../common/crystal_api/rpc';
import { get_selected_db_id_clean } from '../../common/hash';
import { show_ephemeral_toast } from '../../common/toast';

class OptionAttachments extends OptionTable {
  should_show_content() {
    return false;
  }

  is_edited_in_overlay() {
    return false;
  }

  show_current_table() {
    return this.current.length > 0;
  }

  get_template_name() {
    let name = this.type.toLowerCase();
    if (this.current.length < 1 && !this.readonly) {
      name = `create_table`;
    }
    return name;
  }

  template_input_edit_desc() {
    const edit_option_desc = {
      current_option_uuid: this.uuid,
    };
    return [edit_option_desc, 'option/edit_option_attachments'];
  }

  async edit_option_template(full_editing = false) {
    if (!(await super.edit_option_template(full_editing))) return false;
    return true;
  }

  upload_callback(file, resp) {
    document.getElementById(`${this.uid}_input`).value = '';
    this.new_current.push([file.name, Math.round(file.lastModified / 1000), file.size, file.name, '', file.aid]);
    this.do_table(true, false, false, `${this.uid}_table_holder`, true);
    this.aid += 1;
  }

  upload_file() {
    const attach_id = this.current.length;
    this.aid = attach_id;
    const files_list = document.getElementById(`${this.uid}_input`).files;
    if (!files_list || files_list.length == 0) {
      return false;
    }
    for (let file of files_list) {
      const doc_info = {
        uid: this.conf.uid,
        option: this.handle,
        aid: attach_id,
      };
      file.aid = attach_id;
      const cmd = globalThis.user_data.info.customer_id ? rpc.upload : rpc.public_upload;
      cmd(doc_info, file).then((response) => this.upload_callback(file, response));
    }
  }

  apply_changes(should_refresh_view = true) {
    if (!this.new_current.length || (this.new_current[0][0] === '' && this.new_current[0][5] === '')) {
      this.new_current = [];
    }
    if (this.current.toString() !== this.new_current.toString()) {
      this.set_new_current(this.new_current);
    }
    should_refresh_view && this.refresh_view();
  }

  get_share_link(data) {
    const encoded_url = encodeURIComponent(get_selected_db_id_clean() + '/' + this.conf.uid + '/' + this.handle + '/');
    const url = `${globalThis.origin}/rpc/download.php?file=${encoded_url}${data['aid']}&name=${encodeURIComponent(
      data.title
    )}`;
    navigator.clipboard?.writeText(url);
    show_ephemeral_toast(_('copied'), 'Info');
  }

  download_attachments(data) {
    const encoded_url = encodeURIComponent(get_selected_db_id_clean() + '/' + this.conf.uid + '/' + this.handle + '/');
    const url = '../rpc/download.php?file=' + encoded_url + data.aid + '&name=' + encodeURIComponent(data.file);
    globalThis.open(url);
    this.table.deselectRow();
  }

  attchments_context_menu(row, editing = false) {
    const menu = [];
    const data = row['_row']['data'];
    if (editing) {
      menu.push({ label: _('Remove attachment'), action: this.delete_row.bind(this) });
    } else {
      menu.push({ label: _('Share attachment'), action: () => this.get_share_link(data) });
      menu.push({
        label: _('Download attachment'),
        action: () => this.download_attachments(data),
      });
    }
    return menu;
  }

  do_table(editing = false, force = false, full_screen = false, parent_id, should_apply_changes_on_inline_edit) {
    if (this.current.length < 1 && !editing) {
      return;
    }
    if (this.create_table_span) {
      this.create_table_span.remove();
    }
    let data_src = this.current;
    if (editing) data_src = this.new_current;
    const data = this.current_to_user(data_src);
    const table_options = {
      height: '100%',
      rowContextMenu: (e, row) => this.attchments_context_menu(row, editing),
      layout: 'fitColumns',
      pagination: 'local',
      paginationSize: editing ? this.new_current.length : this.current.length,
      paginationSizeSelector: this.pag_size_selector,
      paginationButtonCount: 0,
      data: data,
    };
    table_options.columns = this.define_columns(editing);

    const table = new Tabulator(document.getElementById(parent_id), table_options);
    this.table = table;
    return this.table;
  }

  current_to_user(current = []) {
    if (!current.length) current = this.current;
    return this.value_to_user(current);
  }

  define_columns(editing = false) {
    var header = this.style['header'];
    var columns = [];
    for (var i = 0; i < header.length; i++) {
      var ctype = header[i]['type'];
      var cdef = {
        title: _(header[i]['name']),
        field: header[i]['handle'],
        editable: false,
      };
      if (header[i].handle === 'title') {
        cdef.bottomCalc = 'count';
        if (!editing) {
          var encoded_url = encodeURIComponent(
            get_selected_db_id_clean() + '/' + this.conf.uid + '/' + this.handle + '/'
          );
          var params = '../rpc/download.php?file=' + encoded_url;
          cdef.formatter = 'link';
          cdef.formatterParams = {
            target: '_blank',
            url: function (cell) {
              return (
                params +
                cell.getRow()['_row']['data'].aid +
                '&name=' +
                encodeURIComponent(cell.getRow()['_row']['data'].file)
              );
            },
          };
        } else {
          cdef.editor = this.cellEdit.bind(this, i, 'string');
        }
      }
      if (ctype == 'DateTime') {
        cdef.formatter = function (cell, formatterParams) {
          const d = cell.getValue() || '-';
          return d;
        };
      }
      if (header[i]['handle'] == 'size') {
        cdef.formatter = function (cell, formatterParams) {
          var s = cell.getValue() / 1000000;
          return s;
        };
      }
      cdef.headerTooltip = function (column) {
        return column.getDefinition().title;
      };
      columns.push(cdef);
    }
    return columns;
  }
}

globalThis.option_types['Attachments'] = OptionAttachments;
