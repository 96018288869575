import * as echarts from 'echarts';

function show_overlay_plot(title, name) {
  const input_desc = {
    column: title,
    option_name: name,
  };
  const pie_plot_template = require(`../../view/document/pie_plot_overlay.handlebars`);
  const pie_plot_html = pie_plot_template(input_desc);
  const node = document.createElement('div');
  node.innerHTML = pie_plot_html;
  document.body.appendChild(node);
}

export function option_table_plot(optiontable, key, title) {
  console.log('plot option table column');
  show_overlay_plot(title, optiontable.style.name);
  const pie_chart = echarts.init(document.getElementById('plot_container'), 'dark');
  const series_data = optiontable.table.getData().map((d) => ({ value: d[key], name: `${d['material']}, ${d[key]}%` }));
  pie_chart.setOption({ legend: { show: true }, series: { type: 'pie', data: series_data } });
}
