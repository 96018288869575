import { Editor } from '@toast-ui/editor';
import { Option } from './option';

class OptionTextArea extends Option {
  constructor(current, style, conf) {
    super(current, style, conf);
    this.show_option_textarea = true;
  }

  apply_changes(should_refresh_view = true) {
    this.conf.log.add_value(this.current_from_user(), this.handle);
    super.apply_changes(should_refresh_view);
  }

  value_from_user(value, old = null) {
    if (this.attr.includes('PlainText')) {
      return value;
    }
    if (this.editor) {
      return this.editor['mdEditor']['el'].textContent;
    }
    return '';
  }

  get_editor_text() {
    if (this.current === '' || this.current === '# Document: ') {
      return `**${_('Click to edit text')}**`;
    }
    return this.current;
  }

  set_viewer() {
    const widget_element = document.getElementById(`${this.uid}_textarea_widget`);
    const Viewer = Editor;
    const viewer = Viewer.factory({
      el: widget_element,
      viewer: true,
      usageStatistics: false,
    });
    viewer.setMarkdown(this.get_editor_text());
  }

  should_show_content() {
    return false;
  }

  set_new_current() {
    var nc = this.editor.getMarkdown();
    super.set_new_current(nc);
  }

  get_current_widget_id() {
    return `${this.uid}_textarea_widget`;
  }

  template_input_edit_desc() {
    const edit_option_desc = {
      current_option_uuid: this.uuid,
      option_id: this.uid,
      current: this.current,
      plain_text: this.attr.includes('PlainText'),
    };
    return [edit_option_desc, 'option/edit_option_textarea'];
  }

  set_editor() {
    const input_element = document.getElementById(`${this.uid}_input`);
    let content = `# Document: ${this.conf['currents']['name']}`;
    if (!['', '# Document: '].includes(this.current)) {
      content = this.current;
    }
    this.editor = new Editor({
      el: input_element,
      height: '300px',
      initialValue: content,
      initialEditType: 'markdown',
      previewStyle: 'vertical',
      hideModeSwitch: true,
      usageStatistics: false,
    });
    this.editor.removeToolbarItem('image');
  }
}

globalThis.option_types['TextArea'] = OptionTextArea;
