import { convert_value } from '../common/units';
import { utf_subscript, utf_superscript } from '../common/utils';

const mark_names = {
  Sintering: 'I',
  Softening: 'R',
  Sphere: 'S',
  HalfSphere: 'H',
  Melting: 'M',
  bloating: 'B',
  speedMax: 'V',
  speedMin: 'v',
  dMax: 'D',
  dMin: 'd',
};

function test_dataset(name, str) {
  const reg = new RegExp(`^(\\d+_\\d*_|sample\\d+_)?${name}`);
  return reg.test(str);
}

function get_time_based_plot_ynames(datasets) {
  const names = [];
  datasets.forEach((d) => {
    if (!test_dataset('t', d['handle'])) {
      names.push(d['handle']);
    }
  });
  return names;
}

function get_ynames(datasets) {
  const names = [];
  datasets.forEach((d) => {
    if (!test_dataset('[T,t]', d['handle'])) {
      names.push(d['handle']);
    }
  });
  return names;
}

function get_yAxis_units(yAxis) {
  const units = {};
  yAxis.map((ax, idx) => {
    if (!units[ax['unit']]) {
      units[ax['unit']] = idx;
    }
  });
  return units;
}

function series_data(size, xdata, y_values) {
  const points = [];
  let i = 0;
  while (i < size) {
    const point = [xdata[i]];
    point.push(y_values[i]);
    points.push(point);
    i++;
  }
  return points;
}

function subsup_key(key) {
  const v = key.split('_');
  if (v.length >= 3) {
    return utf_superscript(v[0]) + utf_subscript(v[1]) + v.slice(2).join('_');
  }
  return key;
}

function get_series_desc(points, key) {
  return {
    data: points,
    type: 'line',
    lineStyle: { width: 3 },
    name: subsup_key(key),
    showSymbol: false,
    id: key,
    animation: false,
  };
}

function get_meta_markpoints(doc, chart_name, ynames, key, datasets, points) {
  const ymarks = get_model_marks(doc, ynames);
  const time_dataset = Object.values(datasets).filter((d) => test_dataset('t', d['handle']))[0];
  const mark = ymarks[key] ? get_curve_marks(ymarks[key], time_dataset['style']['dataset'], points, chart_name) : [];
  return mark;
}

function get_series(xname, ynames, datasets, yAxis) {
  const series = [];
  const xdata = datasets[xname].get_dataset();
  const yAxis_units = get_yAxis_units(yAxis);
  ynames.forEach((key) => {
    const size = xdata.length;
    const y_dataset = datasets[key];
    const y_unit = y_dataset['style']['unit'];
    const y_values = y_dataset.get_dataset();
    const points = series_data(size, xdata, y_values);
    const series_desc = get_series_desc(points, key);
    if (test_dataset('T', key)) series_desc['yAxisIndex'] = 1;
    if (!!yAxis_units[cvt[y_unit]]) series_desc['yAxisIndex'] = yAxis_units[cvt[y_unit]];
    series.push(series_desc);
  });
  return series;
}

function get_multiple_curves_series(options, ynames, datasets, xAxis_val, yAxis) {
  const series = [];
  const x_datas = {};
  options.forEach((opt) => (x_datas[opt['handle']] = opt.get_dataset()));
  const yAxis_units = get_yAxis_units(yAxis);
  ynames.forEach((key) => {
    const y_dataset = datasets[key];
    const y_unit = y_dataset['style']['unit'];
    const y_values = y_dataset.get_dataset();
    const curve_index = key.at(0);
    const xdata = x_datas[`${curve_index}__${xAxis_val}`];
    const size = xdata.length;
    const points = series_data(size, xdata, y_values);
    const series_desc = get_series_desc(points, key);
    if (test_dataset('T', key)) series_desc['yAxisIndex'] = 1;
    if (!!yAxis_units[cvt[y_unit]]) series_desc['yAxisIndex'] = yAxis_units[cvt[y_unit]];
    series.push(series_desc);
  });
  return series;
}

function get_curve_marks(options, time, points) {
  const data = [];
  options
    .filter((opt) => opt.current.value !== 'None')
    .forEach((opt, idx) => {
      const index = find_nearest(time, opt.current.time);
      const mark_name = mark_names[opt['handle'].split('_').slice(-1)];
      data.push({ name: opt['handle'], value: mark_name || idx, coord: points[index], parent_opt: opt['name'] });
    });
  for (let i = 0; i < data.length; i++) {
    for (let j = i + 1; j < data.length; j++) {
      if (JSON.stringify(data[i]['coord']) === JSON.stringify(data[j]['coord'])) {
        data[j]['symbolRotate'] = j * 70;
      }
    }
  }
  return data;
}

function is_markpoint_handle(handle) {
  return handle.startsWith('sample') || handle.includes('_');
}

function get_model_marks(doc, ynames) {
  const ymarks = {};
  Object.values(doc.styles)
    .filter((opt) => opt['type'] === 'Meta')
    .forEach((opt) => {
      const handle = opt['handle'];
      if (is_markpoint_handle(handle)) {
        const smp_handle = handle.startsWith('sample') ? handle.split('_')[0] : handle.match(/^.*_.*_/)[0];
        const search = opt['curve'] || smp_handle;
        ynames
          .filter((crv) => crv.startsWith(search))
          .forEach((name) => {
            if (!ymarks[name]) ymarks[name] = [];
            opt.current = doc.currents[handle];
            ymarks[name].push(opt);
          });
      }
    });
  return ymarks;
}

function find_nearest(data, value) {
  for (let idx = 1; idx < data.length; idx++) {
    if (Math.abs(data[idx] - value) > Math.abs(data[idx - 1] - value)) {
      return idx;
    }
  }
  return data.length - 1;
}

function get_chart_container_id(class_name) {
  return class_name.startsWith('Report') ? 'report_plot_div' : 'model_plot_div';
}

function get_toolbox(doc, plot) {
  return {
    itemSize: 20,
    right: '15%',
    feature: {
      myTool: {
        show: true,
        title: _('Show markpoints'),
        icon: markpoint_path,
        onclick: () => plot.switch_markpoints(doc),
      },
      dataZoom: { show: true },
    },
  };
}

function get_x_axis(axis_name, val_unit = 'celsius') {
  return {
    name: axis_name,
    position: 'bottom',
    nameTextStyle: { padding: 10 },
    axisLine: { onZero: false },
    nameLocation: 'middle',
    axisLabel: { formatter: `{value} ${cvt[val_unit]}` },
    triggerEvent: true,
    unit: cvt[val_unit],
    type: 'value',
    scale: true,
  };
}

function get_temperature_y_axis() {
  return {
    type: 'value',
    name: 'Temperature',
    position: 'right',
    alignTicks: true,
    axisLabel: { formatter: '{value} °C' },
    triggerEvent: true,
    unit: 'C',
    scale: true,
  };
}

function get_value_axis(val_unit = 'micron', dataset) {
  return {
    type: 'value',
    name: dataset['name'],
    position: 'left',
    alignTicks: true,
    axisLabel: {
      formatter: (value) => {
        if (value > 1e5) {
          return `${value.toExponential(1)} ${cvt[val_unit] || val_unit}`;
        }
        return `${value} ${cvt[val_unit] || val_unit}`;
      },
    },
    triggerEvent: true,
    unit: cvt[val_unit],
    scale: true,
  };
}

function get_plot_datasets(styles) {
  return Object.values(styles).filter((stl) => stl['dataset']);
}

function get_series_list(options, xnames, ynames, test_string, yAxis) {
  let series = [];
  if (xnames.length === 1) {
    series = get_series(xnames[0], ynames, options, yAxis);
  } else {
    const temp_options = Object.values(options).filter((d) => test_dataset(test_string, d['handle']));
    series = get_multiple_curves_series(temp_options, ynames, options, test_string, yAxis);
  }
  return series;
}

function update_markpoints_x_coords(show_markpoints, copy, from, to, markpoints, s) {
  if (show_markpoints) {
    copy.markPoint?.data.forEach((d) => {
      d['coord'] = [convert_value(d['coord'][0], from, to), d['coord'][1]];
    });
  }
  markpoints?.meta[s['id']].forEach((m) => {
    m['coord'] = [convert_value(m['coord'][0], from, to), m['coord'][1]];
  });
  markpoints?.user?.forEach((m) => {
    if (m['func_params']['seriesId'] === s['id']) {
      m['coord'] = [convert_value(m['coord'][0], from, to), m['coord'][1]];
      m['func_params']['data'] = m['coord'];
    }
  });
}

function update_markpoints_y_coords(show_markpoints, copy, markpoints, s, convert_params) {
  const from = convert_params['from'];
  const to = convert_params['to'];
  const i_dimension = convert_params['initial_dimension'];
  const to_absolute = convert_params['to_absolute'];
  const convert_coord = (val, i_dim, to_absolute) => {
    if (to_absolute) {
      return i_dim * (val / 100);
    } else {
      return (val / i_dim) * 100;
    }
  };
  if (show_markpoints) {
    copy.markPoint?.data.forEach((d) => {
      d['coord'] = [d['coord'][0], i_dimension ? convert_coord(d['coord'][1], i_dimension, to_absolute) : convert_value(d['coord'][1], from, to)];
    });
  }
  markpoints?.meta[s['id']].forEach((m) => {
    m['coord'] = [m['coord'][0], i_dimension ? convert_coord(m['coord'][1], i_dimension, to_absolute) : convert_value(m['coord'][1], from, to)];
  });
  markpoints?.user?.forEach((m) => {
    if (m['func_params']['seriesId'] === s['id']) {
      m['coord'] = [m['coord'][0], i_dimension ? convert_coord(m['coord'][1], i_dimension, to_absolute) : convert_value(m['coord'][1], from, to)];
      m['func_params']['data'] = m['coord'];
    }
  });
}

function convert_percentages_to_values(series, from, to, initial_dimension, show_markpoints, markpoints) {
  const new_series = series.map((s) => {
    const copy = { ...s };
    if (from !== '%') {
      copy.data = copy.data.map((c) => [c[0], convert_value(c[1], from, '%')]);
      update_markpoints_y_coords(show_markpoints, copy, markpoints, s, { from: from, to: '%' });
    }
    copy.data = copy.data.map((c) => [c[0], initial_dimension * (c[1] / 100)]);
    update_markpoints_y_coords(show_markpoints, copy, markpoints, s, { initial_dimension: initial_dimension, to_absolute: true });
    if (to !== 'μm') {
      copy.data = copy.data.map((c) => [c[0], convert_value(c[1], 'μm', to)]);
      update_markpoints_y_coords(show_markpoints, copy, markpoints, s, { from: 'μm', to: to });
    }
    return copy;
  });
  return new_series;
}

function convert_values_to_percentages(series, from, to, initial_dimension, show_markpoints, markpoints) {
  const new_series = series.map((s) => {
    const copy = { ...s };
    if (from !== 'μm') {
      copy.data = copy.data.map((c) => [c[0], convert_value(c[1], from, 'μm')]);
      update_markpoints_y_coords(show_markpoints, copy, markpoints, s, { from: from, to: 'μm' });
    }
    copy.data = copy.data.map((c) => [c[0], (c[1] / initial_dimension) * 100]);
    update_markpoints_y_coords(show_markpoints, copy, markpoints, s, { initial_dimension: initial_dimension, to_absolute: false });
    if (to !== '%') {
      copy.data = copy.data.map((c) => [c[0], convert_value(c[1], '%', to)]);
      update_markpoints_y_coords(show_markpoints, copy, markpoints, s, { from: '%', to: to });
    }
    return copy;
  });
  return new_series;
}

function convert_series_with_initial_dimensions(series, from, to, initial_dimension, show_markpoints, markpoints) {
  const percentages = convert().from('%').possibilities();
  const lengths = convert().from('μm').possibilities();
  let converted_series;
  if (percentages.includes(from) && !percentages.includes(to)) {
    converted_series = convert_percentages_to_values(series, from, to, initial_dimension, show_markpoints, markpoints);
  } else if (lengths.includes(from) && !lengths.includes(to)) {
    converted_series = convert_values_to_percentages(series, from, to, initial_dimension, show_markpoints, markpoints);
  } else {
    converted_series = series.map((s) => {
      const copy = { ...s };
      copy.data = copy.data.map((c) => [c[0], convert_value(c[1], from, to)]);
      update_markpoints_y_coords(show_markpoints, copy, markpoints, s, { from: from, to: to });
      return copy;
    });
  }
  return [converted_series, markpoints];
}

function convert_series_without_initial_dimensions(series_to_convert, from, to, show_markpoints, markpoints, is_xAxis) {
  const converted_series = series_to_convert.map((s) => {
    const copy = { ...s };
    copy.data = copy.data.map((c) => {
      if (is_xAxis) {
        return [convert_value(c[0], from, to), c[1]];
      } else {
        return [c[0], convert_value(c[1], from, to)];
      }
    });
    if (is_xAxis) {
      update_markpoints_x_coords(show_markpoints, copy, from, to, markpoints, s);
    } else {
      update_markpoints_y_coords(show_markpoints, copy, markpoints, s, { from: from, to: to });
    }
    return copy;
  });
  return [converted_series, markpoints];
}

export {
  test_dataset,
  get_time_based_plot_ynames,
  get_ynames,
  get_meta_markpoints,
  get_chart_container_id,
  get_toolbox,
  get_x_axis,
  get_temperature_y_axis,
  get_value_axis,
  get_plot_datasets,
  get_series_list,
  update_markpoints_y_coords,
  update_markpoints_x_coords,
  convert_series_with_initial_dimensions,
  convert_series_without_initial_dimensions,
};
