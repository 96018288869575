import { Option } from './option';

class OptionBoolean extends Option {
  set_table_input(editor, cell, success, cancel, parent_option) {
    const bool_edit = document.createElement('span');
    bool_edit.id = `${this.uid}_table_edit`;
    const table_bool_template = require('../../view/option/currents/boolean_in_tables.handlebars');
    const table_bool_html = table_bool_template({
      option_name: cell['_cell']['row']['data']['row_property'],
      checked: this.current_to_user(this.current),
    });
    bool_edit.insertAdjacentHTML('beforeend', table_bool_html);
    editor.input_edit = bool_edit;
    editor.input_edit.addEventListener('change', () => parent_option.successFunc(editor, cell, success, cancel, false));
    this.is_embedded = true;
    return bool_edit;
  }

  apply_changes(should_refresh_view = true) {
    this.conf.log.add_value(this.current_from_user(), this.handle);
    super.apply_changes(should_refresh_view);
    return;
  }

  get_option_labels(val) {
    let true_label = this.style.options ? this.style.options[0] : 'True';
    let false_label = this.style.options ? this.style.options[1] : 'False';
    let label = val ? true_label : false_label;
    if (this.style.name.includes('AND/OR')) {
      label = val ? 'And' : 'Or';
    }
    return _(label);
  }

  get_current_widget_id() {
    return `${this.uid}_boolean_widget`;
  }

  template_input_edit_desc() {
    const edit_option_desc = {
      current_option_uuid: this.uuid,
      checked_true: this.current_to_user(this.current),
      checked_false: !this.current_to_user(this.current),
    };
    return [edit_option_desc, 'option/edit_option_boolean'];
  }

  current_to_user(value = null) {
    if (value === null) {
      value = this.style.factory_default;
    }
    return ![false, 0, '', 'false'].includes(value);
  }

  current_to_user_view() {
    return this.current_to_user(this.conf.log.get_last_value_of(this.handle)) ? 'fa-check' : 'fa-xmark';
  }

  current_from_user() {
    if (!document.getElementById(`${this.uid}_input`) && !document.getElementById(`${this.uid}_table_edit`)) {
      return this.current;
    }
    let bool_el = this.is_embedded
      ? document.getElementsByName('table_bool_edit')
      : document.getElementsByName('Bool_' + this.handle);

    if (!bool_el.length) {
      return this.current;
    }

    for (var i = 0; i < bool_el.length; i++) {
      if (bool_el[i].checked) {
        bool_el.value = bool_el[i].value;
        const ret = this.current_to_user(bool_el[i].value);
        return ret;
      }
    }
    return false;
  }
}

globalThis.option_types['Boolean'] = OptionBoolean;
