import { Conf } from './conf';
import { show_ephemeral_toast } from '../common/toast';
import { rpc } from '../common/crystal_api/rpc';

export class User extends Conf {
  constructor(currents, styles) {
    super(currents, styles);
    this.is_user_view = true;
  }

  add_search_event() {
    return false;
  }

  get_ignore_options() {
    return [];
  }

  user_new_password(resp) {
    if (resp.error) {
      show_ephemeral_toast(resp.error);
      return false;
    } else {
      location.reload();
    }
  }

  update_pw_data_error() {
    const new_password = document.getElementById('update_new_pw');
    const confirm_password = document.getElementById('update_confirm_pw');
    return new_password.value !== confirm_password.value ? "Password don't match" : false;
  }

  validate_confirm_pw(new_pw_elem) {
    const new_pw = new_pw_elem.value;
    const pw = document.getElementById('update_new_pw').value;
    let err = '';
    if (new_pw !== pw) {
      err = _('Password mismatch');
    }
    new_pw_elem.setCustomValidity(err);
    new_pw_elem.reportValidity();
  }

  confirm_userinfo_update() {
    const doc = { _id: this.currents['_id'] };
    const data_error = this.update_pw_data_error();
    if (!data_error) {
      doc['email'] = document.getElementById('update_current_email').value;
      doc['newPassword'] = document.getElementById('update_new_pw').value;
      doc['confirmPassword'] = document.getElementById('update_confirm_pw').value;
      doc['oldPassword'] = document.getElementById('update_current_pw').value;
      rpc
        .save({
          doc: doc,
          db: 'users',
        })
        .then(this.user_new_password);
    } else {
      show_ephemeral_toast(data_error, 'Info');
    }
  }

  get_document_desc() {
    let doc_active_section = '{';
    const doc_section_list = this.sorted_sections.map((sec_name) => `${sec_name}: ${sec_name === 'main'}`);
    doc_active_section += doc_section_list.join(',');
    doc_active_section += '}';
    const desc = {
      uuid: this.uuid,
      x_data: doc_active_section,
    };
    return desc;
  }

  get_template_file() {
    return 'user_view.handlebars';
  }

  get_view_parent() {
    return 'user_content';
  }

  show_action_buttons() {
    return false;
  }
}
