import * as XLSX from 'xlsx-js-style';
import * as JSONEditor from 'jsoneditor';

import { build_option } from './options/option';
import { rpc } from '../common/crystal_api/rpc';
import { create_modal } from '../common/html_elements';
import { empty_element } from '../common/ui';
import { _ } from '../common/translations';
import { show_dict_properties_pushing_state } from './show_dict_properties';
import { clone_confirm, confirm_doc_creation, remove_document_confirmed } from './document';
import { create_menu_document } from '../common/document_menu';
import { add_controller, remove_controller } from '../common/controllers';
import { hide_visible_toast, show_ephemeral_toast, show_sticky_toast } from '../common/toast';
import { ChangeLog } from './change_log';
import { get_unit, sleep } from '../common/utils';
import { get_vars } from '../common/url';
import { is_report_selected } from '../common/hash';
import { test_dataset } from './plot_functions';

const prevent_cypress_from_failing = (that) => {
  if (!that.view_parent) location.reload();
};

function starts_with_(element) {
  return element.startsWith('_') === false && element.startsWith('@') === false;
}

function format_cells(sheet) {
  sheet['!cols'] = Array.apply(null, { length: 50 }).map(() => ({
    wch: 20,
  }));

  sheet['!rows'] = [{ hpt: 25 }].concat(
    Array.apply(null, { length: 49 }).map(() => ({
      hpt: 20,
    }))
  );
}

export function get_document_type(class_name) {
  return ['Analysis', 'Field'].find((el) => class_name.startsWith(el)) || class_name;
}

export class Conf {
  constructor(currents, styles) {
    this.locals = {};
    this.objid = globalThis.global_counter.next();
    add_controller(this);

    this.original_styles = JSON.parse(JSON.stringify(styles));
    for (let k of Object.keys(currents)) {
      if (k.slice(0, 1) !== '@' || k === '@') {
        continue;
      }
      const k1 = k.slice(1);
      if (styles[k1]) {
        Object.assign(styles[k1], currents[k]);
      } else {
        styles[k1] = currents[k];
        this.locals[k1] = true;
      }
      delete currents[k];
    }

    this.currents = currents;
    this.styles = styles;
    this.options = {};
    this.required_options = this.has_required_options();
    const id = this.currents['_id'] || this.styles['_id'];
    this.uid = id || 'new_';
    if (this.currents.name === 'Comparison') {
      this.uid = 'Comparison';
    }
    this.is_version = id && id.includes(':') && id.split(':')[1].startsWith('ver#');

    this.class_name = this.currents['style'][0].split('#')[1];
    const set_values_from_local_storage = this.pop_from_local_storage();

    this.readonly = !!(styles.writeLevel && styles.writeLevel.current === 6);
    this.create_sections();
    this.is_split_conf = false;
    this.log = new ChangeLog(this.options);
    Object.entries(set_values_from_local_storage).forEach(([key, value]) => {
      Object.keys(this.options).includes(key) && this.log.add_value(value, key);
    });

    this.semaphore = false;

    if (globalThis.perform_search_listenere_already_added) {
      this.add_search_event();
    }
    this.ignore_options = ['name', 'comment'];
  }

  add_search_event() {
    globalThis.addEventListener('perform-search', (e) => {
      let should_reset_query_hash = true;
      if (e.detail) {
        should_reset_query_hash = e.detail.should_reset_query_hash;
      }
      this.do_search(should_reset_query_hash);
    });
    globalThis.perform_search_listenere_already_added = false;
  }

  has_required_options() {
    return false;
  }

  doc_name() {
    return this.currents.name;
  }

  do_search(should_reset_query_hash = true) {}

  name_comment() {
    return false;
  }

  push_formulation_state_to_local_storage() {
    return this.push_to_local_storage('Formulation');
  }

  push_semi_state_to_local_storage() {
    return this.push_to_local_storage('MaterialSemi');
  }

  push_compare_state_to_local_storage() {
    return this.push_to_local_storage('Compare');
  }

  push_to_local_storage(conf_type) {
    const keys_to_match = ['cmp', 'result', 'materials', 'operation', 'objective', 'tolerance', 'minTolerance', 'equality', 'target'];
    const compositions_and_formulation_result = JSON.stringify(
      Object.keys(this.currents)
        .filter((k) => keys_to_match.some((key_to_math) => k.startsWith(key_to_math)))
        .reduce((acc, key) => {
          acc[key] = this.currents[key];
          return acc;
        }, {})
    );

    set_cookie(`new_conf_initial_state_${conf_type}`, compositions_and_formulation_result);
  }

  pop_from_local_storage() {
    const state_cookie_name = `new_conf_initial_state_${get_document_type(this.class_name)}`;
    const state = JSON.parse(get_cookie(state_cookie_name)) || {};
    delete_cookie(state_cookie_name);

    return state;
  }

  can_initiate_a_formulation() {
    const composition_oxides_current = this.currents['cmp_oxide'];
    return !!composition_oxides_current && composition_oxides_current.length > 0;
  }

  can_initiate_a_semi() {
    const result = this.currents['result'];
    return !!result && result.length > 0;
  }

  create_sections() {
    let options_keys = Object.keys(this.styles)
      .sort(this.sorter.bind(this))
      .filter((option_key) => this.styles[option_key]['type'] === 'Section');

    this.sorted_sections = ['main'].concat(
      options_keys.filter((section_name) => Object.values(this.styles).some((option) => option['section'] === section_name))
    );

    let sec = {};
    this.handles().forEach((handle) => {
      const style = this.resolve_style(handle);
      this.options[handle] = this.options[handle] || build_option(this.currents[handle], style, this, this.original_styles[handle]);
      const hidden_option = this.options[handle]['attr'] && !this.options[handle]['attr'].includes('Hidden');
      if (this.options[handle] && hidden_option) {
        const opt_section = this.options[handle]['style']['section'] || 'main';
        sec[opt_section] = sec[opt_section] || [];
        sec[opt_section].push(handle);
      }
    });
    this.sections = sec;
  }

  sorter(item1, item2) {
    return this.styles[item1]['priority'] - this.styles[item2]['priority'];
  }

  apply_values_from_dict(source) {
    Object.keys(this.styles).forEach((k) => {
      if (this.styles[k]['attr']?.includes('Plot')) {
        delete this.currents[k];
        delete this.styles[k];
      }
    });
    const local_done = [];
    let local = false;
    let hs = false;
    let hv = false;
    let opt = undefined;
    for (let h of Object.keys(source)) {
      local = h.slice(0, 1) === '@' && h !== '@';
      hv = h;
      hs = '@' + h;
      if (local) {
        hs = h;
        hv = h.slice(1);
      } else if (source['@' + h]) {
        local = true;
      }
      if (local_done.includes(hv)) {
        continue;
      }
      local_done.push(hv);
      if (local) {
        if (source[hv] === undefined) {
          source[hv] = source[hs]['current'];
        } else {
          source[hs]['current'] = source[hv];
        }
        this.styles[hv] = this.styles[hv] || {};
        Object.assign(this.styles[hv], source[hs]);
        this.currents[hv] = source[hv] === undefined ? this.currents[hv] : source[hv];
      } else {
        this.currents[hv] = source[hv];
      }
      opt = build_option(this.currents[hv], this.styles[hv], this);
      if (opt) {
        this.options[hv] = opt;
      } else {
        console.log('UNSTYLED OPTION', h, hv, hs, this.currents[hv], this.styles[hv], this);
      }
    }
    this.create_sections();
  }

  restore_originals() {
    const that = this;
    const changed_entries = this.log.restore();

    Object.keys(changed_entries).forEach((key) => {
      that.options[key].current = changed_entries[key].current;
      that.options[key].new_current = changed_entries[key].current;
      that.options[key].style.csunit = changed_entries[key]?.style?.csunit || that.options[key].style.unit;
      that.options[key].refresh_view();
    });
    return true;
  }

  save_changes() {
    let new_option_currents = { _id: this.currents['_id'] };
    const changes = this.log.get_changes();
    for (let key of Object.keys(this.options)) {
      let v = this.options[key].current;
      if (document.getElementById(`confirm_${key}_edit`)?.checked === false) {
        this.log.restore_handle(key);
        v = this.log.get_last_value_of(key);
      }
      if (changes[key] && Object.keys(changes[key][2]).length) {
        new_option_currents['@' + key] = changes[key][2];
      }
      new_option_currents[key] = v;
    }
    rpc.save({ doc: new_option_currents }).then((response) => this.save_changes_callback(response));
  }

  confirm_edit_dialog() {
    const options = this.get_changes_msg(true);
    if (options) {
      const params = {
        modal_id: 'save_document',
        modal_title: 'Confirm save',
        button_id: 'save_changes_button',
        button_title: 'Save',
        button_func: () => this.save_changes(),
      };
      this.changes_dialog(params, options, true);
    }
  }

  confirm_revert_dialog() {
    const options = this.get_changes_msg();
    if (options) {
      const params = {
        modal_id: 'restore_original',
        modal_title: 'Confirm restore',
        button_id: 'restore_original_button',
        button_title: 'Restore',
        button_func: () => this.restore_originals(),
      };
      this.changes_dialog(params, options, false);
    }
  }

  get_changes_msg(save = false) {
    if (save && !this.options['name'].current) {
      show_sticky_toast(`${_('Field cannot be empty')}: <b>Name</b>`, 'Error');
      return false;
    }
    const options_to_exclude = Object.keys(this.options).filter((key) => this.styles[key]['attr'] && this.styles[key]['attr'].includes('Runtime'));
    const all_changes = this.log.get_changes(options_to_exclude);
    const options = [];
    Object.keys(all_changes).forEach((key) => {
      const [original, current, style] = all_changes[key];
      const option = this.options[key];
      const name = option['style']['name'] || option['handle'];
      if (Object.keys(style).length) {
        Object.entries(style).forEach(([k, val]) => {
          options.push({ option_handle: option['handle'], option_name: `${name} (${k}):`, current_value: val });
        });
        if (original !== current) {
          options.push({ option_handle: option['handle'], option_name: `${name}:`, current_value: current, original_value: original });
        }
      } else {
        if (typeof current === 'object') {
          options.push({ option_handle: option['handle'], option_name: `${_('Option')} ${name}` });
        } else {
          options.push({ option_handle: option['handle'], option_name: `${name}:`, current_value: current, original_value: original });
        }
      }
    });
    return options;
  }

  changes_dialog(params, options, show_checkbox) {
    const edits_template = require('../view/option/edit_dialog_template.handlebars');
    const edits_html = edits_template({ options_list: JSON.stringify({ options_list: options }), checkbox: show_checkbox });
    create_modal(
      {
        id: params['modal_id'],
        title: params['modal_title'],
        content: edits_html,
      },
      [
        {
          button_id: params['button_id'],
          button_text: params['button_title'],
          func: params['button_func'],
          delete_modal: true,
        },
      ]
    );
  }

  view_style_source(doc) {
    if (this.jeditorstyle) {
      this.jeditorstyle.destroy();
      this.jeditorstyle = undefined;
      remove_element('document_style_viewer');
      return true;
    }
    const parent_element = document.getElementById(`${this.uid}_div`);
    const container_template = `<div id='document_style_viewer' class='py-2.5'></div>`;
    parent_element.insertAdjacentHTML('beforeend', container_template);
    const options = {
      name: doc._id + ' source document',
      mode: 'view',
    };
    this.jeditorstyle = new JSONEditor(document.getElementById('document_style_viewer'), options);
    this.jeditorstyle.set(doc);
    this.jeditorstyle.collapseAll();
    return true;
  }

  view_document_source(doc) {
    if (this.jeditor) {
      this.jeditor.destroy();
      this.jeditor = undefined;
      remove_element('document_currents_viewer');
      return true;
    }
    const parent_element = document.getElementById(`${this.uid}_div`);
    const container_template = `<div id='document_currents_viewer' class='py-2.5'></div>`;
    parent_element.insertAdjacentHTML('beforeend', container_template);
    const options = {
      name: doc._id + ' source document',
      mode: 'view',
    };
    this.jeditor = new JSONEditor(document.getElementById('document_currents_viewer'), options);
    this.jeditor.set(doc);
    this.jeditor.collapseAll();
    return true;
  }

  handles() {
    return Object.keys(this.currents).filter(starts_with_).filter(this.resolve_style.bind(this)).sort(this.sorter.bind(this));
  }

  resolve_style(handle) {
    let style = this.currents['@' + handle];
    if (!style) {
      style = this.styles[handle];
    }
    return style;
  }

  show_document_section(section_name, sections) {
    let toggle = ``;
    for (let d of sections) {
      if (section_name !== d) {
        toggle += `${d} = false;`;
      } else {
        toggle += `${d} = true;`;
      }
    }
    return toggle;
  }

  get_sections_tab(j, section) {
    const secid = 'section_' + section[j];
    const old_section = document.getElementById(secid);
    if (old_section) {
      old_section.parentNode.removeChild(old_section);
    }
    return {
      tab_id: `${secid}-tab`,
      tab_text: _(section[j]),
      sec_name: section[j],
      tab_click: this.show_document_section(section[j], section),
    };
  }

  activate_section(secidx) {
    console.log('Activate section');
    globalThis.active_document_tab = secidx;
    const secopts = this.sections[secidx];
    if (secopts) {
      for (let key of secopts) {
        this.options[key].activate_section();
      }
    }
  }

  value_changed() {
    return;
  }

  get_document_desc() {
    this._check_section();
    let default_section_name = globalThis.active_document_tab;
    if (this.class_name === 'Compare') default_section_name = 'cmp_main';
    let doc_active_section = '{';
    const doc_section_list = this.sorted_sections.map((sec_name) => `${sec_name}: ${sec_name === default_section_name}`);
    doc_active_section += doc_section_list.join(',');
    doc_active_section += '}';
    let doc_name = this.doc_name();

    const name_uuid = (this.options.name && this.options.name.uuid) || false;
    const comment_uuid = (this.options.comment && this.options.comment.uuid) || false;
    const desc = {
      doc_uid: this.uid,
      doc_name: doc_name,
      doc_comment: this.currents.comment,
      doc_name_uuid: name_uuid,
      doc_comment_uuid: comment_uuid,
      doc_type: get_document_type(this.class_name).toUpperCase(),
      object_id: this.objid,
      show_version: this.uid.includes(':ver#'),
      current_version: this.currents && this.currents.version,
      x_data: doc_active_section,
      doc_icon: font_awesome_classes[get_document_type(this.class_name)],
    };
    return desc;
  }

  get_sections_tab_list() {
    let tabs_list = [];
    const hide_main = this.sections['main'].every((current) => this.options[current]['type'] === 'Section');
    for (let j = 0; j < this.sorted_sections.length; j++) {
      const sec = this.sorted_sections[j];
      const s = this.styles[sec];
      if ((s && !s['attr'].includes('Hidden')) || (sec === 'main' && !hide_main)) {
        tabs_list.push(this.get_sections_tab(j, this.sorted_sections));
      }
    }
    return JSON.stringify({
      sections_tab_list: tabs_list,
    });
  }

  get_sections_content_list(ignore = []) {
    const section_desc = [];
    for (let j = 0; j < this.sorted_sections.length; j++) {
      const h = this.sections[this.sorted_sections[j]];
      const current_section_options = [];
      for (let i = 0; i < h.length; i++) {
        const current_option = this.options[h[i]];
        if (current_option.handle.includes('_m3_') || current_option.type === 'Section') {
          ignore.push(current_option.handle);
        }
        if (!ignore.includes(current_option.handle)) {
          const option_uuid = current_option.create_template_option(this.class_name.startsWith('Report'));
          current_section_options.push(option_uuid);
        }
      }
      section_desc.push([`section_${this.sorted_sections[j]}`, this.sorted_sections[j], current_section_options]);
    }
    return JSON.stringify({
      section_contents_list: section_desc,
    });
  }

  get_template_file() {
    return 'document_view.handlebars';
  }

  get_all_options() {
    const options = [];
    for (let j = 0; j < this.sorted_sections.length; j++) {
      const h = this.sections[this.sorted_sections[j]];
      for (let i = 0; i < h.length; i++) {
        options.push(this.options[h[i]]);
      }
    }
    return options;
  }

  full_edit_or_show_normal() {
    if (this.is_new()) {
      this.full_edit();
    } else {
      const options = this.get_all_options();
      for (let opt of options) {
        opt.add_widget_listener();
      }
    }
  }

  document_view_ready() {
    this.full_edit_or_show_normal();
    this.show_action_buttons();
    this.semaphore = true;
  }

  get_view_parent() {
    return 'document_container';
  }

  get_ignore_options() {
    return this.ignore_options;
  }

  view() {
    this.view_parent = document.getElementById(this.get_view_parent());
    document.title = this.doc_name();

    let doc_elems_list = this.get_document_desc();
    doc_elems_list['conf_uuid'] = this.uuid;
    doc_elems_list['sections_tab_list'] = this.get_sections_tab_list();
    doc_elems_list['section_contents_list'] = this.get_sections_content_list(this.get_ignore_options());
    if (this.class_name === 'User') {
      doc_elems_list['email'] = globalThis.user_data['info']['email'];
      doc_elems_list['conf_uuid'] = this.uuid;
    }

    const view_template_file = this.get_template_file();
    const document_template = require(`../view/document/${view_template_file}`);
    const document_html = document_template(doc_elems_list);

    prevent_cypress_from_failing(this);
    this.view_parent?.insertAdjacentHTML('beforeend', document_html);
  }

  show_action_buttons() {
    create_menu_document(`${this.uid}_title`, this);
  }

  is_dirty() {
    return this.log.is_dirty();
  }

  is_search() {
    return false;
  }

  is_new() {
    return this.uid.startsWith('def:Style#');
  }

  is_full_edit() {
    let count = 0;
    for (let opt of Object.values(this.options)) {
      if (opt.readonly || opt.hidden) {
        continue;
      }
      if (!opt.is_editing()) {
        return false;
      }
      count += 1;
      if (count > 1) {
        return true;
      }
    }
    return false;
  }

  create_batch_from_mat() {
    console.log('Creating batch from material');
    confirm_doc_creation('Batch', this.currents);
  }

  set_creating_doc_name(modal_id) {
    const val = document.getElementById('create_doc_name');
    console.log('set name', modal_id);
    if (val.value && !['', ' ', false].includes(val.value)) {
      this.currents['name'] = val.value;
      this.options['name']['current'] = val.value;
      this.save_full_edit_and_refresh_table();
      document.getElementById(modal_id).remove();
    } else {
      console.log('Invalid name');
      return false;
    }
  }

  save_full_edit_and_refresh_table() {
    this.save_full_edit();
    refresh_table();
  }

  async save_full_edit() {
    let option_values = {
      style: this.currents['style'],
      class_name: this.class_name,
    };
    if (!this.options['name'].current_from_user() && this.currents['name'] === '') {
      const template = `
      <div>
        <label for="create_doc_name" class="block text-sm font-medium text-gray-700" x-text='_("New document name:")'></label>
        <div class="relative mt-1 rounded-md">
          <input type="text" name="create_doc_name" id="create_doc_name" placeholder="Name" pattern="[a-zA-Z0-9 ]*" required
            class="peer block w-full rounded-md border border-gray-500 px-2 focus:!border-elsred focus:!ring-elsred sm:text-sm"
          >
          <p class="mt-2 !invisible peer-invalid:!visible text-red-500 text-sm" x-text='_("Invalid document name")'>
          </p>
        </div>
      </div>
      `;
      create_modal(
        {
          id: 'create_doc',
          title: _('Create document'),
          content: template,
        },
        [
          {
            button_text: 'Confirm',
            button_id: 'confirm_doc_creation_name',
            func: this.set_creating_doc_name.bind(this, 'create_doc'),
            delete_modal: false,
          },
        ]
      );
      document.getElementById('create_doc_name').focus();
      return;
    }
    for (let h of Object.keys(this.options)) {
      if (!this.options[h]) {
        continue;
      }
      const o = this.options[h];
      if (o.handle === 'name' && o.current_from_user() === '') {
        option_values[o.handle] = this.currents[o.handle];
        continue;
      }
      option_values[o.handle] = await o.current_from_user();
    }
    for (const value of Object.values(option_values)) {
      if (value.error) {
        show_sticky_toast(`${value.error}`, 'Error');
        return;
      }
    }
    console.log('saving document', option_values);
    rpc.create({ doc: option_values }).then(
      (response) => this.save_changes_callback(response),
      (error) => {
        console.log(error);
      }
    );
  }

  update_options_current_from(initial_values) {
    const handles = this.handles();
    Object.keys(initial_values).forEach((k) => {
      if (handles.includes(k)) {
        this.options[k]['current'] = initial_values[k];
      }
    });
    this.get_all_options();
  }

  full_edit(initial_values = get_vars()) {
    this.update_options_current_from(initial_values);
    const options = this.get_all_options();
    for (let opt of options) {
      const overlay_edit = ['Table', 'Role', 'MultiRole', 'Attachments'].includes(opt.type);
      const genome_skip = this.uid === 'def:Style#SearchGenome#0' && this.skip && this.skip.includes(opt.handle);
      const valid_option = document.getElementById(opt.uid) && !opt.readonly;
      if (valid_option && !overlay_edit && !genome_skip) {
        opt.edit_option_template(true);
      }
      opt.add_widget_listener();
    }
    return true;
  }

  save_changes_callback(response) {
    if (response.error) {
      show_ephemeral_toast(`${response.error}`, 'Error');
      return;
    }
    this.log.clean_all();
    const target = document.getElementById('document_container');

    rpc
      .doc({
        uid: response['document_id'] || response['uid'],
      })
      .then((response) => {
        if (is_report_selected()) {
          show_report_updating_history(response['_id']);
        } else {
          show_dict_properties_pushing_state(target, response);
        }
      })
      .then(() => show_ephemeral_toast(_('Edit saved'), 'Info'));
    set_query_param('docId');
  }

  clone_document() {
    const elem = this.currents;
    const template = `
      <div>
        <label for="clone_input" class="block text-sm font-medium text-gray-700" x-text='_("New document name:")'></label>
        <div class="relative mt-1 rounded-md">
          <input type="text" name="clone_input" id="clone_input" placeholder="Name" pattern="^[a-zA-Z0-9 ]*$" required
            class="peer block w-full rounded-md border border-gray-500 px-2 focus:!border-elsred focus:!ring-elsred sm:text-sm"
          />
          <p id="clone_error_msg" class="mt-2 invisible peer-invalid:visible !text-elsred text-sm" x-text='_("invalid-name")'>
          </p>
        </div>
      </div>
      `;
    create_modal(
      {
        id: 'clone_doc',
        title: `${_('Clone document')}: ${elem['name']}`,
        content: template,
      },
      [
        {
          button_text: 'Clone',
          button_id: 'clone_doc_confirm',
          func: () => clone_confirm(elem, 'clone_doc'),
          delete_modal: false,
        },
      ]
    );
    document.getElementById('clone_input').focus();
    return;
  }

  remove_document() {
    console.log('delete doc', this.currents);
    if (this.currents.referees.length > 0) {
      show_ephemeral_toast(_('delete-document-impossible'), 'Error');
      return false;
    } else {
      create_modal(
        {
          id: 'delete_doc',
          title: _('Delete document'),
          content: _('confirm-delete'),
        },
        [
          {
            button_text: 'Delete document',
            button_id: 'delete_doc_confirm',
            func: () => remove_document_confirmed(this, this.currents._id),
            delete_modal: true,
          },
        ]
      );
    }
  }

  as_doc() {
    this.semaphore = false;
    let doc = [];
    let dict_value = null;
    for (let [key, value] of Object.entries(this.options)) {
      if (value === null) {
        console.log('Warning: null option', key);
        continue;
      }
      if (!this.styles[key]) {
        console.log('Warning: unstyled option', key, value);
        continue;
      }
      if (this.styles[key]['attr']?.includes('Runtime')) {
        console.log('Skipping Runtime', key);
        continue;
      }
      dict_value = value.current_from_user();
      const value_is_promise = typeof dict_value.then === 'function';
      const that = this;
      if (value_is_promise) {
        (async () => {
          dict_value = await dict_value;
          while (!this.semaphore) await sleep(50);
          that.options[key].current = dict_value;
          that.options[key].refresh_view();
        })();
      } else {
        doc.push({ key: key, value: dict_value });
        if (that.styles[key] && that.styles[key].auto && that.styles[key].auto !== 'auto') {
          doc.push({ key: `@${key}`, value: { auto: that.styles[key].auto } });
        }
        if (that.locals[key]) {
          doc.push({ key: `@${key}`, value: that.styles[key] });
        }
      }
    }
    doc = Object.assign({}, ...doc.map((x) => ({ [x.key]: x.value })));
    doc.class_name = this.class_name;
    doc['_id'] = this.uid;
    return doc;
  }

  execute() {
    hide_visible_toast();
    const doc = this.as_doc();
    let cannot_execute = false;
    let error_message = '';
    if (!doc) {
      cannot_execute = true;
      error_message = _('Cannot execute the document');
    }
    console.log('get execute doc', doc);
    const is_comparison = doc['style'][0] === 'def:Style#Compare#0';
    const is_formulation = doc['style'][0] === 'def:Style#Formulation#0';
    if (is_comparison) {
      if (!doc['delta']) doc['properties'] = [];
      if (doc['properties'].length) {
        doc['properties'] = doc['properties'].filter((p) => ['true', true, 1].includes(p[2]));
      }
      if (doc['docs'].length < 2) {
        cannot_execute = true;
        error_message = _('2-docs-needed');
      }
    } else if (is_formulation) {
      if ([doc['materials'], doc['target']].some((d) => d.length < 1)) {
        cannot_execute = true;
        error_message = _('missing-materials');
      }
    }
    for (let k of Object.keys(doc)) {
      if (k.endsWith('_opt')) {
        delete doc[k];
      }
    }
    if (cannot_execute) {
      show_ephemeral_toast(_(error_message), 'Error');
      return new Promise((r) => r());
    }

    return rpc.execute({ doc: doc }).then((response) => this.execute_callback(response));
  }

  reset() {
    for (let opt of Object.keys(this.options)) {
      if (this.options[opt]) {
        this.options[opt].close();
      }
      delete this.options[opt];
    }
    empty_element(this.view_parent);
  }

  close() {
    this.reset();
    remove_controller(this);
  }

  executed = 0;

  execute_feedback_toast(resp, sticky_toast_on_success) {
    if (resp && resp.error) {
      show_sticky_toast(`${resp.error}`, 'Error');
      return;
    }
    let message = this.feedback_message ? this.feedback_message : 'Document executed successfully';
    message = resp.message ? resp.message : message;
    const title = this.feedback_title ? this.feedback_title : 'Info';
    const toast_function = sticky_toast_on_success ? show_sticky_toast : show_ephemeral_toast;
    toast_function(message, title);
  }

  execute_callback(resp, sticky_toast_on_success = false) {
    this.execute_feedback_toast(resp, sticky_toast_on_success);
    const dict = resp.doc;
    this.reset();
    this.detect_changes_on_execute(dict);
    this.apply_values_from_dict(dict);
    this.view();
    this.executed += 1;
  }

  detect_changes_on_execute(dict) {
    Object.entries(dict)
      .filter(([key, _]) => !key.startsWith('_') && !key.startsWith('@'))
      .forEach(([key, val]) => {
        if (val === undefined) {
          val = null;
        }
        if (JSON.stringify(val) !== JSON.stringify(this.currents[key])) {
          // FIXME: style must be the whole definition of the option style
          this.log.add_value(val, key, { handle: key, current: val });
        }
      });
  }

  get_export_document_style() {
    const document_title_style = {
      alignment: { vertical: 'center', wrapText: false },
      font: { bold: true, color: { rgb: 'FFFFFFFF' } },
      fill: { fgColor: { rgb: 'FFe53d3d' } },
    };
    return [
      [{ v: 'Document ' + this.currents.name, s: document_title_style }].concat(
        Array.apply(null, { length: 50 }).map(() => ({ v: '', s: document_title_style }))
      ),
    ];
  }

  append_sections_sheets_to_export(ws_data, book) {
    this.sorted_sections.forEach((section) => {
      const sheet = XLSX.utils.aoa_to_sheet(ws_data);
      let current_row = 3;
      const previous_row = current_row;
      this.sections[section]
        .filter((key) => this.options[key].type !== 'Section')
        .forEach((key) => {
          if (!this.ignore_options.includes(key)) {
            current_row = this.options[key].fill_cell(sheet, current_row);
          }
        });
      format_cells(sheet);
      const rows_were_added = previous_row !== current_row;
      rows_were_added && XLSX.utils.book_append_sheet(book, sheet, _(section));
    });
  }

  export_dataset_rows(opt) {
    const handle = opt.handle;
    const initial_dimension = opt['style']['initialDimension'] || opt['style']['dataset'].slice(0, 10).reduce((acc, val) => acc + val, 0) / 10;
    const dataset_map = {
      time: 'min',
      temp: 'K',
    };
    const unit_column_header = test_dataset('t', handle) ? dataset_map['time'] : test_dataset('T', handle) ? dataset_map['temp'] : '%';
    const rows = [
      [{ v: opt.name, s: { font: { bold: true } } }],
      [{ v: 'Initial dimension', s: { font: { bold: true } } }, initial_dimension],
      [{ v: handle, s: { font: { bold: true } } }, { v: get_unit(opt.style['unit']) }, { v: unit_column_header }],
    ];
    return rows;
  }

  append_datasets_sheets_to_export(ws_data, book) {
    Object.values(this.options)
      .filter((option) => option['style']['dataset'])
      .forEach((opt) => {
        const sheet = XLSX.utils.aoa_to_sheet(ws_data);
        const rows = this.export_dataset_rows(opt);
        XLSX.utils.sheet_add_aoa(sheet, rows, { origin: 'A3' });
        let current_row = 3 + rows.length + 1;
        XLSX.utils.sheet_add_aoa(sheet, [[{ v: 'Data', s: { font: { bold: true } } }]], { origin: 'A' + current_row });
        opt['style']['dataset'].forEach((pt) => {
          if (test_dataset('t', opt.handle)) {
            XLSX.utils.sheet_add_aoa(sheet, [[pt, { f: `B${current_row}/60` }]], { origin: 'B' + current_row });
          } else if (test_dataset('T', opt.handle)) {
            XLSX.utils.sheet_add_aoa(sheet, [[pt, { f: `B${current_row}+273.15` }]], { origin: 'B' + current_row });
          } else {
            XLSX.utils.sheet_add_aoa(sheet, [[pt, { f: `100*B${current_row}/$B$4` }]], { origin: 'B' + current_row });
          }
          current_row += 1;
        });
        format_cells(sheet);
        XLSX.utils.book_append_sheet(book, sheet, _(opt.name));
      });
  }

  prepare_export_document(book) {
    const ws_data = this.get_export_document_style();
    const sheet = XLSX.utils.aoa_to_sheet(ws_data);
    format_cells(sheet);
    const that = this;
    const summary_sections = Object.keys(this.sections).filter((key) => that.options[key] && that.options[key].style['in_summary']);
    summary_sections.forEach((section) => {
      let row = 3;
      this.sections[section]
        .filter((key) => this.options[key].type !== 'Section')
        .forEach((key) => {
          row = this.options[key].fill_cell(sheet, row);
        });
      format_cells(sheet);
    });
    summary_sections.length > 0 && XLSX.utils.book_append_sheet(book, sheet, _('Summary'));
    this.append_sections_sheets_to_export(ws_data, book);
    this.append_datasets_sheets_to_export(ws_data, book);
    return book;
  }

  export_document() {
    console.log('export doc', this);
    const book = XLSX.utils.book_new();
    this.prepare_export_document(book);
    const ext = '.xlsx';
    const name = this.uid.startsWith('def:Style#') ? `new ${this.class_name}${ext}` : this.currents.name + ext;
    XLSX.writeFile(book, name);
  }

  _check_section() {
    if (!this.sorted_sections.includes(globalThis.active_document_tab)) {
      globalThis.active_document_tab = 'main';
    }
  }
}

globalThis.Conf = Conf;
