import { empty_element, hide_element_with_opacity_transition, show_element_with_opacity_transition } from '../common/ui';
import { rpc } from '../common/crystal_api/rpc';
import { get_vars } from '../common/url';
import { setUrlVars } from '../common/utils';
import { show_search_form_and_table } from './show_search_form_and_table';
import { get_selected_db_id } from '../common/hash';
import { hide_visible_toast, show_ephemeral_toast } from '../common/toast';

function build_conf(currents, styles) {
  console.log('build_conf');
  let key = currents['mtype'] || currents['class_name'] || styles['_id'].slice(1, -2);
  const material_class = globalThis.material_classes[key];
  if (material_class) {
    return new material_class(currents, styles);
  } else {
    return new globalThis.Conf(currents, styles);
  }
}

export function get_style(doc, elem, response) {
  console.log('get style');
  if (response.error && (!response.class_name || !response.class_name.current.startsWith('Model'))) {
    show_ephemeral_toast(_('missing-document'), 'Error');
    show_search_form_and_table(globalThis.show_materials_table);
    return;
  }
  const conf = build_conf(doc, response);
  delete globalThis.current_document_conf;
  globalThis.current_document_conf = conf;
  empty_element(elem);
  conf.view(elem);
  show_element_with_opacity_transition(elem.id);
}

function show_dict_properties(elem, doc) {
  console.log('show_dict_properties');
  if (doc.error) {
    show_ephemeral_toast(_('missing-document'), 'Error');
    show_search_form_and_table(globalThis.show_materials_table);
    return;
  }
  if (elem && elem.innerHTML !== '') {
    hide_element_with_opacity_transition(elem.id);
  }

  hide_visible_toast();
  return rpc.doc({ uid: doc['style'][0] }).then((rpc_response) => get_style(doc, elem, rpc_response));
}

function show_dict_properties_pushing_state(elem, response) {
  show_dict_properties(elem, response);

  const params = get_vars();
  params['uid'] = response['_id'];
  const path = `${get_selected_db_id()}?${setUrlVars(params)}`;
  globalThis.history.pushState(path, 'Show', path);
}

export { show_dict_properties, show_dict_properties_pushing_state, build_conf };
