import { is_genome_selected } from '../../common/hash';
import { OptionMultiChooser } from './option_chooser';

class OptionMultiChooserClasses extends OptionMultiChooser {
  get_all_childrens(selectedOpt) {
    return Array.from(document.getElementsByClassName(`mChooser_${selectedOpt.value.split('_')[1]}`));
  }

  template_input_edit_desc() {
    const [materials, analysis, others, reports, models] = this.get_genome_chooser_desc();
    const edit_option_desc = {
      current_option_uuid: this.uuid,
      materials: materials,
      analysis: analysis,
      others: others,
      reports: reports,
      models: models,
      show_genome: is_genome_selected(),
      show_report: !is_genome_selected(),
    };
    return [edit_option_desc, 'option/edit_option_genome_classes'];
  }

  multichooser_on_change(selected_element) {
    this.conf.search_doc_classes ||= [];

    const selected_entry = selected_element.options[selected_element.selectedIndex];

    const select_all_entries = selected_entry.value.startsWith('all_');
    const select_no_entries = selected_entry.value.startsWith('none_');
    const selected_entry_children = this.get_all_childrens(selected_entry);

    select_all_entries && this.select_entries(selected_entry_children);
    select_no_entries && this.unselect_entries(selected_entry_children);
    const single_entry_selected = !select_all_entries && !select_no_entries;
    single_entry_selected && this.toggle_selection(selected_entry);

    this.set_tooltip();
    document.getElementById(`${this.handle}_default_option`).selected = true;
  }

  toggle_selection(selected_entry) {
    if (!this.conf.search_doc_classes.includes(selected_entry.value)) {
      this.select_entry(selected_entry);
    } else {
      this.unselect_entry(selected_entry);
    }
  }

  unselect_entry(selected_entry) {
    selected_entry.classList.remove('font-bold');
    const index = this.conf.search_doc_classes.indexOf(selected_entry.value);
    index > -1 && this.conf.search_doc_classes.splice(index, 1);
  }

  select_entry(selected_entry) {
    selected_entry.classList.add('font-bold');
    const index = this.conf.search_doc_classes.indexOf(selected_entry.value);
    index === -1 && this.conf.search_doc_classes.push(selected_entry.value);
  }

  select_entries(options) {
    Array.from(options).forEach((option) => this.select_entry(option));
  }

  unselect_entries(options) {
    Array.from(options).forEach((option) => this.unselect_entry(option));
  }

  set_tooltip() {
    document.getElementById(`${this.uid}_input`).title = this.get_tooltip();
  }

  get_tooltip() {
    const searchDocClasses = this.conf.search_doc_classes.map((c) => _(c));
    return searchDocClasses.join('\n');
  }
}

globalThis.option_types['OptionMultiChooserClasses'] = OptionMultiChooserClasses;
