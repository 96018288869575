import { hide_element, populate_genome_search_form } from '../common/ui';
import { get_vars } from '../common/url';
import { rpc } from '../common/crystal_api/rpc';

globalThis.glaze_search_table = undefined;

export async function do_search() {
  globalThis.search_genome_object.options.queryHash.current = '';
  let model_div = document.getElementById('model_plot_div');
  if (model_div) {
    hide_element(model_div);
    Array.from(model_div.children).forEach((cld) => hide_element(cld));
  }
  let query_doc;
  const queryHash = get_vars()['queryHash'];
  if (queryHash && !globalThis.search_genome_object.should_not_load_form_data) {
    const style = await rpc.doc({ uid: `def:Style#SearchGenome#0` });
    query_doc = await rpc
      .doc({ uid: `0G:SearchGenome#${queryHash}` }, undefined, undefined, undefined, false)
      .catch(() => {
        set_query_param_updating_history('queryHash');
        return globalThis.search_genome_object.as_doc();
      });
    populate_genome_search_form(style, query_doc);
  } else {
    query_doc = globalThis.search_genome_object.as_doc();
  }
  delete query_doc['modified'];
  let dest = false;
  let needs_filtering = true;
  globalThis.glaze_search_table = false;

  return show_materials_table(query_doc, false, dest, needs_filtering);
}

globalThis.do_search = do_search;
