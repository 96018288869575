import { Option } from './option';
import '../../common/global_functions';
import { map_object } from '../../common/utils';

class OptionMeta extends Option {
  get_temp_and_unit() {
    const temp_unit = globalThis.get_symbol(this.style.unit['temp'] || 'celsius');
    const temp = this.current_to_user()['temp'];
    return `${temp} ${temp_unit}`;
  }

  get_time_and_unit() {
    const unit = this.style.csunit ? this.style.csunit['time'] : this.style.unit['time'] || 'second';
    const time_unit = globalThis.get_symbol(unit);
    const time = this.current_to_user()['time'];
    return `${time} ${time_unit}`;
  }

  value_to_user(values_object) {
    return map_object(values_object, (v) => format_value(v));
  }

  should_show_content() {
    return false;
  }

  fill_cell(sheet, r) {
    if (this.handle.includes('_m3_')) {
      return r;
    }
    XLSX.utils.sheet_add_aoa(sheet, [[{ v: _(this.name), s: { font: { bold: true } } }, this.get_temp_and_unit(), this.get_time_and_unit()]], {
      origin: 'A' + r,
    });
    r = r + 1;
    return r;
  }
}

function format_value(value) {
  if (!value || value === 'None') {
    return globalThis.empty_data_placeholder;
  }

  const decimal_numbers = value.toString().split('.')[1];
  if (decimal_numbers && decimal_numbers.length > 2) {
    return value.toFixed(2);
  }
  return value;
}

globalThis.option_types['Meta'] = OptionMeta;
