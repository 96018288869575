import { sleep } from '../common/utils';
import { hide_element_with_opacity_transition, show_element } from '../common/ui';
import { _ } from '../common/translations';
import { show_document_by_uid } from './document';
import { get_selected_db_id, get_selected_db_id_clean } from '../common/hash';
import { ajaxRequestFunc } from '../common/ajax_request_func';
import { show_ephemeral_toast } from '../common/toast';
import { table_columns_definition } from '../common/tabulator/autocolumns_definitions';
import { create_tabulator } from '../common/tabulator/tabulator_factory';
import { add_doc_to_fast_compare } from './fast_compare';
import { get_document_type } from './conf';

async function ensure_genome_table_is_visible_to_avoid_tabulator_errors() {
  dispatch_event('show-search-table');
  await sleep(50);
}

class SearchTable {
  constructor(doc, destination = undefined, include_searches_in_results = undefined) {
    this.table = undefined;
    const classes0 = doc.classes;
    if (doc.nameComment && (!doc.classes || doc.classes.length === 0)) {
      doc.classes = [
        'MaterialRaw',
        'MaterialSemi',
        'MaterialElement',
        'MaterialCompound',
        'MaterialOxide',
        'MaterialProduct',
        'MaterialMix',
        'MaterialDummy',
        'Company',
        'Logistic',
        'Place',
        'UseCase',
        'Agent',
        'CustomLabel',
        'PreparationMethod',
        'PhysicalForm',
        'Tag',
        'Batch',
        'Instrument',
        'Sample',
        'Formulation',
        'Analysis',
        'AnalysisHsm',
        'AnalysisOxides',
        'AnalysisMinerals',
        'AnalysisDilatometer',
        'AnalysisGranulometry',
        'Compare',
      ];
      if (include_searches_in_results) {
        doc.classes.push('SearchGenome');
      }
    }
    this.doc = doc;
    this.last_query = doc;
    this.search_db = get_selected_db_id_clean();
    this.url_search_param = '';
    if (classes0 && classes0.length > 0) {
      this.url_search_param = `${get_selected_db_id()}?classes=${doc.classes.toString()}`;
    }
    if (doc.nameComment) {
      if (this.url_search_param) {
        this.url_search_param += '&';
      } else {
        this.url_search_param = `${get_selected_db_id()}?`;
      }
      this.url_search_param += 'nameComment=' + doc.nameComment;
    }
    if (destination && destination === 'compare') {
      this.compare_dest = destination;
    } else {
      this.destination = destination;
    }
    this.cmd = 'list';
    if (doc.id_for_versions) {
      this.cmd = 'versions';
      doc.uid = doc.id_for_versions;
    }
    if (destination && destination.style && destination.style.target) {
      if (destination.style.target.some((r) => r.startsWith('Report'))) {
        this.search_db = { Demo: 'ReportDemo', Company: 'Report' }[this.search_db] || 'Report';
      }
    }
  }

  rows_error_toast(message) {
    show_ephemeral_toast(_(message), 'Error');
  }

  remove_default_row(destination) {
    const rows = destination.table.getRows();
    if (rows.length) {
      const first_column_handle = destination.header[0]['handle'];
      let first_row_value = rows[0].getData()[first_column_handle];
      if (first_row_value === 'default') {
        destination.table.deleteRow(rows[0]);
        destination.new_current.shift();
      }
    }
  }

  update_destination_table(dest_new_current) {
    this.destination.new_current = dest_new_current;
    const items = this.destination.value_to_user(dest_new_current);
    this.destination.table.clearData();
    items.forEach((item, idx) => {
      this.destination.table.addRow(item, false, items[idx - 1]);
    });
    this.destination.table.setPageSize(this.destination.new_current.length);
  }

  add_doc_to_role() {
    const rows = this.table.getSelectedRows();
    this.table.deselectRow();
    if (rows.length !== 1) {
      this.rows_error_toast('No rows selected');
      return;
    }
    const doc = rows[0]['_row']['data'];
    this.destination.receive_new_current_from_table(doc);
  }

  material_table_rowClick(e, row) {
    if (!discard_dirty_state()) {
      return;
    }

    const uid = row['_row']['data']['_id'];
    if (e.ctrlKey) {
      window.open(`#2/0G?uid=${uid}`);
    } else {
      hide_element_with_opacity_transition('document_container');
      show_document_by_uid(uid);
      globalThis.search_genome_object.should_not_load_form_data = true;
    }
  }

  async show_table(last_query, ignore_query_hash = false) {
    const that = this;
    let row_click_function = () => {};

    let table_tooltip = _('Click to show document');
    if (!this.destination && !this.compare_dest) {
      row_click_function = (e, row) => this.material_table_rowClick(e, row);
    } else if (this.compare_dest && globalThis.comparing) {
      table_tooltip = _('Click to add to comparison');
      row_click_function = (e, row) => add_doc_to_fast_compare(row['_row']['data']);
    } else if (this.destination) {
      table_tooltip = _('Click to add');
      row_click_function = (e, row) => this.select_row(e, row);
    }

    const ajaxParams = function () {
      return {
        cmd: that.cmd,
        search_db: that.search_db,
      };
    };

    await ensure_genome_table_is_visible_to_avoid_tabulator_errors();

    const table = create_tabulator(
      '#search_content_table',
      ajaxParams,
      ajaxRequestFunc(genome_parse_new_data, last_query, ignore_query_hash),
      table_columns_definition(table_tooltip),
      false,
      row_click_function
    );

    show_element('search_div');
    this.table = table;

    return true;
  }

  select_row(e, row) {
    if (this.destination.type === 'Role') {
      row.select();
      this.add_doc_to_role();
    }
  }
}

function show_materials_table_ignoring_query_hash(
  doc,
  newState = true,
  destination = undefined,
  needs_filtering = false
) {
  return show_materials_table(doc, newState, destination, needs_filtering, true);
}

function show_materials_table(
  doc,
  newState = true,
  destination = undefined,
  needs_filtering = false,
  ignore_query_hash = false
) {
  console.log('show_materials_table', doc, destination);

  const not_in_main_search = !!destination;

  if (!globalThis.glaze_search_table || not_in_main_search || globalThis.material_table_is_dirty || needs_filtering) {
    if (globalThis.glaze_search_table) {
      globalThis.glaze_search_table.table = undefined;
      globalThis.glaze_search_table = undefined;
    }
    globalThis.material_table_is_dirty = not_in_main_search;
    globalThis.glaze_search_table = new SearchTable(doc, destination);
    globalThis.glaze_search_table.show_table(doc, ignore_query_hash);
  }

  newState && globalThis.history.pushState('', null, get_selected_db_id());

  return globalThis.glaze_search_table;
}

function genome_parse_new_data(response) {
  const data = response.list || response;
  try {
    const filtered_data = data.map((value) => {
      value.class_name = get_document_type(get_icon_class_name(value['_id'], value['class_name']));
      return value;
    });
    return [filtered_data, response];
  } catch {
    console.warn('IMPROPER RESPONSE FROM BACKEND: ', response);
    return [response, response];
  }
}

export { genome_parse_new_data };
globalThis.show_materials_table = show_materials_table;
globalThis.show_materials_table_ignoring_query_hash = show_materials_table_ignoring_query_hash;
