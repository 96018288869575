import { hide_element } from '../common/ui';
import { SplitConf } from './split_conf';

class Compare extends SplitConf {
  constructor(currents, styles) {
    super(currents, styles);
    this.is_compare = true;
  }

  execute_callback(resp) {
    console.log('Compare callback', resp.doc);
    this.feedback_message = 'Document comparison results';
    super.execute_callback(resp);
  }

  full_edit_or_show_normal() {
    super.full_edit_or_show_normal();
    if (this.currents['properties'].length === 0) {
      this.hide_option('properties');
    }
  }

  hide_option(handle) {
    if (!this.options[handle]) {
      console.log('Option not found', handle);
      return false;
    }
    hide_element(this.options[handle]['uid']);
    return true;
  }

  apply_values_from_dict(source) {
    for (let s of Object.values(this.styles)) {
      if (s.type === 'Section' && !['main', 'Admin'].includes(s.name)) {
        delete this.styles[s.handle];
      }
    }
    super.apply_values_from_dict(source);
  }

  pop_from_local_storage() {
    const state = super.pop_from_local_storage();
    const that = this;
    ['target_type', 'docs', 'delta'].forEach((field) => {
      if (state[field] !== undefined && state[field] !== null) {
        that.currents[field] = state[field];
      }
    });
    return state;
  }
}

globalThis.material_classes.Compare = Compare;
