function convert_to_python_timestamp_with_time(timestamp, milliseconds, seconds, minutes, hours) {
  const date = new Date(timestamp);
  date.setMilliseconds(milliseconds);
  date.setSeconds(seconds);
  date.setMinutes(minutes);
  date.setHours(hours);
  return date.valueOf() / 1000;
}

function min_date_timestamp(min) {
  return convert_to_python_timestamp_with_time(min, 0, 0, 0, 0);
}

function max_date_timestamp(max) {
  return convert_to_python_timestamp_with_time(max, 999, 59, 59, 23);
}

export { min_date_timestamp, max_date_timestamp };
