import { model_plot } from './model_plot';
import { SplitConf } from './split_conf';
import { show_sticky_toast } from '../common/toast';
import { hide_element, show_element, show_element_with_opacity_transition } from '../common/ui';

class Model extends SplitConf {
  constructor(currents, styles) {
    super(currents, styles);
    this.is_model = true;
    this.left_active_section = 'main';
    this.right_active_section = 'Results';
    this.plots_configurations = {};
    globalThis.addEventListener('option_value_restored', () => this.value_changed('option_value_restored'));
  }

  doc_name() {
    return this.currents['name'] ? this.currents['name'] : this.currents['mtype'];
  }

  view() {
    super.view();
    show_element_with_opacity_transition('document_container');
    if (!this.is_new()) {
      model_plot(this);
      const plots = {};
      Object.values(this.styles).map((stl) => {
        if (stl['dataset'] && stl['plot']) {
          plots[stl['plot']] = 1;
        }
      });
      Object.keys(plots).forEach((plot) => {
        model_plot(this, plot, false);
      });
    }
  }

  execute_feedback_toast(resp) {
    const dict = resp.doc;
    if ((!dict.success || dict.success === 0) && dict.message) {
      show_sticky_toast(`${dict.message || ''}`, 'Error');
    }
  }

  execute_callback(resp) {
    console.log('Model execute callback');
    super.execute_callback(resp);
    this.template_split_view();
    this.show_action_buttons();
    model_plot(this);
    const plots = {};
    Object.values(this.styles).map((stl) => {
      if (stl['dataset'] && stl['plot']) {
        plots[stl['plot']] = 1;
      }
    });
    Object.keys(plots).forEach((plot) => {
      model_plot(this, plot, false);
    });
  }

  get_template_file() {
    if (!this.is_splitted_view) {
      return 'document_view.handlebars';
    }
    return 'model_view.handlebars';
  }

  show_document_section(section_name, sections) {
    let left_sections = sections.filter((name) => !['Results'].includes(name));
    let right_sections = ['Results'];
    if (!this.is_splitted_view) {
      left_sections = [];
      right_sections.splice(0, 0, 'main');
      right_sections.push('Admin');
    }
    let toggle = ``;
    if (left_sections.includes(section_name)) {
      for (let d of left_sections) {
        if (section_name !== d) {
          toggle += `${d} = false;`;
        } else {
          toggle += `${d} = true;`;
        }
      }
    } else {
      for (let d of right_sections) {
        if (section_name !== d) {
          toggle += `${d} = false;`;
        } else {
          toggle += `${d} = true;`;
        }
      }
    }
    return toggle;
  }

  get_sections_tab_list() {
    const left_tabs_list = [];
    const right_tabs_list = [];
    this.sorted_sections.forEach((sec, idx) => {
      const hidden = this.styles[sec] && this.styles[sec]['attr'].includes('Hidden');
      if ((sec === 'Results' && !hidden) || !this.is_splitted_view) {
        right_tabs_list.push(this.get_sections_tab(idx, this.sorted_sections));
      } else {
        left_tabs_list.push(this.get_sections_tab(idx, this.sorted_sections));
      }
    });
    const tab_list = this.is_splitted_view
      ? {
          left_sections_tab_list: left_tabs_list,
          right_sections_tab_list: right_tabs_list,
          show_right_sections: !!right_tabs_list.length,
        }
      : { sections_tab_list: right_tabs_list };
    return JSON.stringify(tab_list);
  }

  get_sections_content_list(ignore = []) {
    const left_section_desc = [];
    const right_section_desc = [];
    this.sorted_sections.forEach((sec) => {
      const h = this.sections[sec];
      const current_section_options = [];
      for (let i = 0; i < h.length; i++) {
        const current_option = this.options[h[i]];
        if (current_option.type !== 'Section' && !ignore.includes(current_option.handle)) {
          const option_uuid = current_option.create_template_option();
          current_section_options.push(option_uuid);
        }
      }
      if (sec === 'Results' || !this.is_splitted_view) {
        right_section_desc.push([`section_${sec}`, sec, current_section_options]);
      } else {
        left_section_desc.push([`section_${sec}`, sec, current_section_options]);
      }
    });
    const section_list = this.is_splitted_view
      ? { left_section_contents_list: left_section_desc, right_section_contents_list: right_section_desc }
      : { section_contents_list: right_section_desc };
    return JSON.stringify(section_list);
  }

  split_view_full_edit() {
    this.full_edit();
    if (!this.executed) {
      hide_element(document.getElementById('model_right_div'));
      hide_element(document.getElementById('model_rTab'));
      hide_element(document.getElementById('model_rContent'));
    }
  }

  template_split_view() {
    show_element(document.getElementById('model_left_div'));
    if (this.sections['Results']) {
      show_element(document.getElementById('model_right_div'));
      show_element(document.getElementById('model_rTab'));
      show_element(document.getElementById('model_rContent'));
    }
  }

  restore_originals() {
    super.restore_originals();
    model_plot(this);
  }

  get_document_desc() {
    if (!this.is_splitted_view) this.right_active_section = this.left_active_section;
    let sections = '{';
    const data_array = this.sorted_sections.map(
      (sec_name) => `${sec_name}: ${sec_name === this.left_active_section || sec_name === this.right_active_section}`
    );
    sections += data_array.join(',');
    sections += '}';
    const desc = super.get_document_desc();
    desc['x_data'] = sections;
    return desc;
  }

  value_changed(event_name = '') {
    if (event_name === 'option_value_restored') model_plot(this);
  }

  pop_from_local_storage() {
    const state = super.pop_from_local_storage();
    if (this.class_name === 'ModelPlot' && state['_id']) {
      this.currents.tests = [[[state['_id'], state['name']]]];
    } else if (this.class_name === 'ModelQuartz' && state['_id']) {
      this.currents.test = [state['_id'], state['name']];
    }
    return state;
  }
}

globalThis.material_classes.Model = Model;

class ModelBase extends Model {}
class ModelPlot extends Model {}
class ModelCTE extends Model {}
class ModelCoupling extends Model {}
class ModelViscosity extends Model {}
class ModelSintering extends Model {}
class ModelQuartz extends Model {}

globalThis.material_classes.ModelBase = ModelBase;
globalThis.material_classes.ModelPlot = ModelPlot;
globalThis.material_classes.ModelCTE = ModelCTE;
globalThis.material_classes.ModelCoupling = ModelCoupling;
globalThis.material_classes.ModelViscosity = ModelViscosity;
globalThis.material_classes.ModelSintering = ModelSintering;
globalThis.material_classes.ModelQuartz = ModelQuartz;
