import chroma from 'chroma-js';
import { normalize } from '../../common/utils';
import { collect } from 'collect.js';

function apply_scale(value, scale) {
  if (isNaN(value)) {
    return undefined;
  }
  return scale(value);
}

export function get_color_coding_for(values) {
  values = Array.from(
    values.map((value) => parseInt(Math.round(parseFloat(value) * 10000)))
  ).filter((value) => !isNaN(value));

  const min_color = 'deeppink';
  const max_color = 'red';
  const middle_color = 'white';

  const scale_positive = chroma.scale([middle_color, max_color]);
  const scale_negative = chroma.scale([min_color, middle_color]);

  const min_value = Math.min(...values);
  const max_value = Math.max(...values);

  const positive_values = values.map((value) =>
    value >= 0 ? value : undefined
  );
  const negative_values = values.map((value) =>
    value < 0 ? value : undefined
  );

  const normalized_positive_values = positive_values.map(
    normalize(0, max_value)
  );
  const normalized_negative_values = negative_values.map(
    normalize(min_value, 0)
  );

  const positive_colors = normalized_positive_values.map((value) =>
    apply_scale(value, scale_positive)
  );

  const negative_colors = collect(
    normalized_negative_values.map((value) =>
      apply_scale(value, scale_negative)
    )
  );

  const all_colors = negative_colors
    .zip(positive_colors)
    .all()
    .map(([neg, pos]) => {
      if (neg) return neg;
      return pos;
    });

  return all_colors.map((color) => color.hex());
}
