import { init_genome_navbar } from '../common/navbar';
import { show_element } from '../common/ui';
import { hide_visible_toast, show_ephemeral_toast } from '../common/toast';

export function show_search_form_and_table(show_materials_table) {
  if (some_option_is_dirty()) {
    show_ephemeral_toast(_('unsaved-edits'), 'Error');
    return;
  }
  show_element('search_div');
  const name = '';
  const classes = globalThis.all_materials_classes;
  show_materials_table({ nameComment: name, classes: classes }, true);
  document.getElementById('search_in_home').value = '';
  init_genome_navbar();
  hide_visible_toast();
  document.title = 'Ceramics Genome';
  if (document.getElementById('clear_search_btn')) {
    document.getElementById('clear_search_btn').click();
  }
  if (document.getElementById('save_search_btn')) {
    show_element('save_search_btn', 'inline-block');
  }
  if (document.getElementById('load_search_btn')) {
    show_element('load_search_btn', 'inline-block');
  }
}
