import { capitalize_string } from '../../common/utils';

typed_types = {
  integer: ['Integer', 'Boolean'],
  float: ['Float', 'Progress', 'Time'],
  binary: ['Binary', 'Image'],
  string: [
    'String',
    'TextArea',
    'Script',
    'Section',
    'FileList',
    'Date',
    'Preset',
    'Button',
    'ThermalCycle',
    'DateTime',
  ],
  multicol: [
    'Meta',
    'Rect',
    'Point',
    'Role',
    'RoleIO',
    'GPS',
    'RangeInteger',
    'RangeFloat',
    'RangeDate',
    'LogList',
  ],
  pickle: [
    'ReadOnly',
    'Hidden',
    'Chooser',
    'List',
    'Profile',
    'Table',
    'Attachments',
    'Log',
    'MultiRole',
    'Curve',
    'Stat',
  ],
};
bytype = {};
for (const [k, v] of Object.entries(typed_types)) {
  for (t of v) {
    bytype[t] = k;
  }
}

num_types = typed_types['integer'].concat(typed_types['float']);

function validate(entry) {
  /*Verify coherence of option `entry`*/
  if (!entry['handle']) {
    console.log('No handle for', entry, ': skipping!');
    return false;
  }
  //Type guessing
  var etype = entry['type'];
  if (!etype) {
    var cur = entry['current'];
    if (cur % 1 === 0) {
      etype = 'Integer';
    } else if (typeof cur == typeof '') {
      etype = 'String';
    } else if (cur % 1 != 0) {
      etype = 'Float';
    } else if (Array.isArray(cur)) {
      etype = 'List';
    } else if (typeof cur == 'object' && !Array.isArray(cur)) {
      if (
        Object.keys(cur).length == 3 &&
        cur['temp'] &&
        cur['time'] &&
        cur['value']
      ) {
        etype = 'Meta';
      }
      if (Object.keys(cur).length == 2 && cur['T'] && cur['v']) {
        etype = 'Curve';
      }
    } else {
      console.log('No type for', entry, ': skipping!');
      return False;
    }
    entry['type'] = etype;
  }
  //Redundancy integration
  var v = '';
  if (!entry['current']) {
    if (etype in num_types) {
      v = 0;
    } else if (['List', 'LogList', 'Profile'].includes(etype)) {
      v = [];
    } else if (etype == 'Meta') {
      v = { temp: 'None', time: 'None', value: 'None' };
    } else if (etype == 'Stat') {
      v = { size: 0 };
    } else if (etype == 'Log') {
      v = [0, 'log'];
    } else if (etype == 'Curve') {
      v = { T: [], v: [] };
    } else if (['String', 'Binary', 'FilePath'].includes(etype)) {
      v = '';
    } else if (etype == 'Rect') {
      v = [0, 0, 0, 0];
    } else if (etype == 'Point' || etype.startsWith('Range')) {
      v = [0, 0];
    } else if (etype == 'Role') {
      v = ['None', 'default'];
    }
  }
  entry['current'] = v;
  if (!entry['chron'] || entry['chron'].length != 2) {
    entry['chron'] = [[], []];
  }

  if (etype == 'RoleIO' && !entry['optionss']) {
    entry['options'] = ['None', 'default', 'None'];
  }
  if (!entry['flags']) {
    entry['flags'] = {};
  }
  // 0=always visible; 1=user ; 2=expert ; 3=advanced ; 4=technician ;
  // 5=developer; 6=never visible
  if (!entry['readLevel']) {
    entry['readLevel'] = 0;
  }
  if (!entry['writeLevel']) {
    // Inizializzo al livello readLevel+1
    entry['writeLevel'] = entry['readLevel'] + 1;
  }
  if (entry['current'] && !entry['factory_default']) {
    entry['factory_default'] = entry['current'];
  } else if (entry['factory_default'] && !entry['current']) {
    entry['current'] = entry['factory_default'];
  }
  if (!entry['name']) {
    var n = entry['handle'].replace('_', ' ');
    n = capitalize_string(n);
    entry['name'] = n;
  }
  if (!entry['parent']) {
    entry['parent'] = false;
  }
  if (entry['parent'] == entry['handle']) {
    console.log('Option parent must differ from handle!');
    entry['parent'] = false;
  }
  if (!entry['unit']) {
    if (entry['type'] == 'Meta') {
      entry['unit'] = { time: 'second', temp: 'celsius', value: 'None' };
    } else {
      entry['unit'] = 'None';
    }
  }
  if ([null, 'none', undefined].includes(entry['current'])) {
    entry['current'] = 'None';
  }
  // add attr field if not present
  if (!entry['attr']) {
    entry['attr'] = [];
  }
  // add maximum=1 for Progress
  if ((etype == 'Progress') & !entry['max']) {
    entry['max'] = 1;
  }
  return entry;
}

function ao(
  d,
  handle = false,
  type = 'Empty',
  current = null,
  name = false,
  { priority, parent, flags, unit, options, values, attr, kid },
  kw = {}
) {
  if (!handle) {
    console.log('NO HANDLE');
    return d;
  }
  if (kw['attrs']) {
    console.log('ATTRS IS INVALID');
    return d;
  }
  var attr = attr || [];
  var flags = flags || {};

  if (current == null || current == undefined) {
    if (['float', 'integer'].includes(bytype[type])) {
      current = 0;
    } else if (
      ['List', 'Table', 'RoleList', 'MultiRole', 'Attachments'].includes(type)
    ) {
      current = [];
    } else if (type == 'Chooser' && 'MultiChooser' in attr) {
      current = [];
    } else if (type == 'Meta') {
      current = { temp: 'None', time: 'None', value: 'None' };
    } else if (type == 'Stat') {
      current = { size: 0 };
    } else if (type == 'LogList') {
      current = [];
    } else if (type == 'Curve') {
      current = { cycle: [], T: [], v: [], label: '' };
    } else if (type == 'Rect') {
      current = [0, 0, 640, 480];
    } else if (type == 'Point') {
      current = [0, 0];
    } else if (type == 'Log') {
      current = [0, 'log']; // level, message
    } else if (type == 'Profile') {
      current = [];
    } else if (type.startsWith('Range')) {
      current = [0, 0];
    } else if (type == 'Role') {
      current = ['None', 'default'];
    } else {
      current = '';
    }
  } else if (!['Object', 'Binary', 'Image', 'Profile'].includes(type)) {
    current = current;
  }

  if (!name) {
    name = handle;
  }

  if (priority < 0 || priority == null) {
    priority = d.length;
  }

  var ent = {
    priority: priority,
    handle: handle,
    name: name,
    current: current,
    factory_default: current,
    readLevel: 0,
    writeLevel: 0,
    type: type,
    kid: kid,
    attr: attr,
    flags: flags,
    parent: parent,
    chron: [[], []],
  };
  ent = Object.assign(ent, kw);

  if (type == 'MultiRole') {
    ent['header'] = ao([], 'role', 'Role', (name = 'Role'));
    if (ent['target']) {
      ent['header'][0]['target'] = ent.pop('target');
    }
  }
  if (values != false) {
    ent['values'] = values;
  }
  if (options != false) {
    ent['options'] = options;
  } else if (type == 'RoleIO') {
    ent['options'] = ['None', 'default', 'None'];
  } else if (['Chooser', 'FileList'].includes(type)) {
    ent['options'] = [];
  }
  if (unit != null) {
    ent['unit'] = unit;
  } else if (type == 'Meta') {
    ent['unit'] = { time: 'second', temp: 'celsius', value: 'None' };
  }
  ent = validate(ent);

  if (d == undefined) {
    return ent;
  } else {
    d.push(ent);
  }
  return d;
}
