import { rpc } from '../common/crystal_api/rpc';

function search_material(query_doc, query_options, search_performed = false) {
  const input_docs = document.getElementById('search_in_home');
  const overlay_opts = globalThis.search_genome_object.options;

  const nameComment_option = overlay_opts['nameComment'];
  const elem = document.getElementById(`${nameComment_option.uid}_input`);
  elem.value = input_docs.value;
  globalThis.search_genome_object.name_comment(nameComment_option);
  show_materials_table(query_doc, query_options.newState, query_options.dest, search_performed);
}

export function refresh_material_table(element_id = 'search_in_home') {
  const dict = {};
  const query_options = {};
  query_options['newState'] = true;
  query_options['dest'] = {};
  dict.nameComment = document.getElementById(element_id).value;
  dict.classes = globalThis.all_materials_classes;
  if (globalThis.glaze_search_table) {
    query_options['newState'] = false;
    query_options['dest'] = globalThis.glaze_search_table.destination;
    if (query_options['dest']) {
      dict.classes =
        query_options['dest']['type'] === 'Role'
          ? query_options['dest']['style']['target']
          : query_options['dest']['style']['header'][0]['target'];
    }
    if (typeof dict.classes === 'string') {
      //Lambda cmd needs an array of element
      dict.classes = [dict.classes];
    }
    return rpc.list(dict).then(() => search_material(dict, query_options, true));
  }
}
