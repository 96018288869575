import { Option } from './option';
import { show_element } from '../../common/ui';
import { get_document_hash } from '../../common/utils';
import { show_tests_table } from '../../ligo/tests';
import { is_genome_selected } from '../../common/hash';

class OptionRole extends Option {
  get_template_name() {
    if (this.current[1] === 'default' && !this.readonly) {
      return `set_role`;
    }
    return 'role';
  }

  add_widget_listener() {
    const set_role = document.getElementById(`${this.uid}_set_role`);

    set_role?.addEventListener(`set_${this.uid}_role`, () => this.edit_option_template());
  }

  get_current_widget_id() {
    if (this.current[0] === 'None' && this.current[1] === 'default') {
      return `${this.uid}_set_role`;
    }
    return `${this.uid}_role_widget`;
  }

  should_show_content() {
    false;
  }

  get_role_href() {
    const href = `${location.origin}/${get_document_hash(this.current[0], true)}`;
    return href;
  }

  value_to_user(value, old = null) {
    if (typeof value == 'object' && value.length === 2) {
      return value[1];
    } else if (typeof value === 'string') {
      return value.split('-')[1];
    }
    return old || 'empty';
  }

  get_input_value() {
    const user_friendly_current = this.current[1];
    return user_friendly_current === 'default' ? '' : user_friendly_current;
  }

  template_input_edit_desc() {
    const edit_option_desc = {
      current_option_uuid: this.uuid,
      option_id: this.uid,
      current: this.current[1],
    };
    return [edit_option_desc, 'option/edit_option_role'];
  }

  edit_option_template(full_editing = false) {
    super.edit_option_template(full_editing);
    document.getElementById('search_in_home').value = '';
    document.getElementById('search_in_home').setAttribute('disabled', 'disabled');
    document.getElementById('expand_search_div').classList.add('hidden');
    const target = this.style['target'];
    show_element('search_div');
    if (is_genome_selected()) {
      show_materials_table_ignoring_query_hash({ classes: target }, false, this);
    } else {
      globalThis.report_search_doc['destination'] = this;
      globalThis.report_search_doc['target'] = target;
      globalThis.current_search_event = 'perform-model-role-search';
      show_tests_table({ classes: target }, this);
    }
    globalThis.history.pushState('Select Role', 'Select Role', globalThis.location);
  }

  search_table_for_role_option(value, target) {
    if (is_genome_selected()) {
      let dict = { classes: target, nameComment: value };
      if (target.some((t) => t.includes('Report'))) {
        dict = { classes: target, title: value };
      }
      show_materials_table_ignoring_query_hash(dict, false, this);
    } else {
      show_tests_table({ classes: target, title: value }, this);
    }
  }

  receive_new_current_from_table(doc) {
    if (this.name === 'Parent Material') {
      return;
    }
    const role = [doc['_id'], doc['name']];
    const input_element = document.getElementById(`${this.uid}_input`);
    input_element.value = doc['name'];
    input_element.dispatchEvent(new Event('change'));

    this.new_current = role;
    this.update_history();
  }

  current_from_user() {
    let new_current = ['None', 'default'];
    if (!document.getElementById(`${this.uid}_input`)) {
      return this.current || new_current;
    }
    if (this.new_current !== this.current) {
      new_current = this.new_current;
    }
    return this.value_from_user(new_current);
  }

  refresh_view() {
    globalThis.current_search_event = 'perform-search';
    document.getElementById('search_in_home').removeAttribute('disabled');
    document.getElementById('expand_search_div').classList.remove('hidden');
    if (is_genome_selected()) {
      show_materials_table({});
    } else {
      show_tests_table({});
    }
    super.refresh_view();
  }
}

globalThis.option_types['Role'] = OptionRole;
