import { rpc } from '../../common/crystal_api/rpc';
import { Option } from './option';

class OptionCaptcha extends Option {
  get_current_widget_id() {
    return `${this.uid}_captcha_widget`;
  }

  captcha_image_url() {
    return this.style.image ? `data:image/jpeg;base64,${this.style.image}` : '';
  }

  template_input_edit_desc() {
    const edit_option_desc = {
      current_option_uuid: this.uuid,
    };
    return [edit_option_desc, 'option/edit_option_captcha'];
  }

  current_from_user() {
    const input_container = document.getElementById(`edit_${this.uid}`);
    if (!input_container) {
      return this.current;
    }
    let input_value = document.getElementById(`${this.uid}_captcha_input`) || {};
    return [this.current[0], this.value_from_user(input_value.value)];
  }

  recaptcha() {
    rpc.public_captcha().then((response) => {
      this.style.image = response.image;
      this.current[0] = response.key;
      this.refresh_view();
      this.edit_option_template(true);
    });
  }
}

globalThis.option_types['Captcha'] = OptionCaptcha;
