import { Conf } from './conf';

class MaterialSemi extends Conf {
  pop_from_local_storage() {
    const state = super.pop_from_local_storage();
    if (state['result']) {
      this.currents.cmp_semi = state['result'];
    }

    return state;
  }
}

globalThis.material_classes ||= {};
globalThis.material_classes.semi = MaterialSemi;

export {};
