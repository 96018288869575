import { Option } from './option';
import { TabulatorFull as Tabulator } from 'tabulator-tables';
import { setCurve } from '../../ligo/thermal_cycle';
import { create_modal } from '../../common/html_elements';
import { dateToISO, remove_element } from '../../common/ui';
import { _ } from '../../common/translations';
import { export_header_style, export_title_style } from '../../common/export_elements';

class OptionLogList extends Option {
  constructor(current, style, conf) {
    super(current, style, conf);
    this.show_option_loglist = true;
  }

  value_to_user(value) {
    var data = [];
    if (!value) return '-';
    for (const [key, val] of Object.entries(value)) {
      for (const v of val) {
        var d = {};
        var t = dateToISO(new Date(v[0] * 1000));
        d['key'] = key;
        d['t'] = t;
        d['p'] = v[1];
        d['msg'] = v[2];
        data.push(d);
      }
    }
    return data;
  }

  define_columns() {
    var columns = [
      {
        title: _('Key'),
        field: 'key',
        editable: false,
        minWidth: 20,
      },
      {
        title: _('Time'),
        field: 't',
        editable: false,
        minWidth: 50,
      },
      {
        title: _('Priority'),
        field: 'p',
        editable: false,
        minWidth: 20,
      },
      {
        title: _('Message'),
        field: 'msg',
        editable: false,
        minWidth: 100,
      },
    ];
    return columns;
  }

  render_loglist_table() {
    if (this.type === 'LogList') {
      return this.do_table();
    }
  }

  do_table() {
    const parent = document.getElementById(`${this.handle}_loglist_table`);
    if (Object.keys(this.current).length === 0 || !parent) {
      return true;
    }
    const table_options = {
      height: '100%',
      layout: 'fitDataStretch',
      pagination: 'local',
      paginationSize: 3,
      paginationButtonCount: 0,
      columns: this.define_columns(),
      data: this.value_to_user(this.current),
    };
    new Tabulator(parent, table_options);
    return false;
  }

  hide_table(el, d, key) {
    remove_element(`${this.handle}_${key}_table`);
    this.table_element = null;
    el.innerHTML = 'Show';
    el.onclick = () => this.create_log_tables(key);
  }

  create_log_tables(key) {
    const btn = document.getElementById(`${key}_input`);
    btn.innerHTML = 'Hide';
    const log_title = document.getElementById(`${key}_title`);
    const table = `<table id="${this.handle}_${key}_table" class="pl-0.5"></table>`;
    log_title.insertAdjacentHTML('beforeend', table);
    this.table_element = document.getElementById(`${this.handle}_${key}_table`);
    btn.onclick = () => this.hide_table(btn, log_title, key);
    this.do_table(key);
  }

  should_show_content() {
    return false;
  }
}

class OptionStat extends Option {
  constructor(current, style, conf) {
    super(current, style, conf);
    this.show_option_stat = true;
  }

  value_to_user(value) {
    var stats = {};
    stats['st_ctime'] = value['st_ctime'] ? ['Created at: ', this.date_from_ts(new Date(value['st_ctime'] * 1000))] : undefined;
    stats['st_mtime'] = value['st_mtime'] ? ['Modified at: ', this.date_from_ts(new Date(value['st_mtime'] * 1000))] : undefined;
    stats['st_size'] = value['st_size'] ? ['Size: ', (value['st_size'] / 1048576).toFixed(2) + ' MB'] : undefined;
    return stats;
  }

  date_from_ts(date) {
    console.log('DATE FROM TS');
    var r =
      date.getDate() +
      '/' +
      (date.getMonth() + 1) +
      '/' +
      date.getFullYear() +
      ' ' +
      date.getHours() +
      ':' +
      date.getMinutes() +
      ':' +
      date.getSeconds();
    return r;
  }

  show_all_stats() {
    console.log('showing stats');
    const stats = this.current_to_user();
    const template = `
    <p>
      <div id="report_stats_div">
      </div>
    <p>
    `;
    create_modal(
      {
        id: 'report_stats',
        title: _('Full stats'),
        content: template,
      },
      [
        {
          button_id: 'dismiss_stats_modal',
          button_text: 'Close',
          func: () => {},
          delete_modal: true,
        },
      ],
      false
    );
    for (let s of Object.values(stats)) {
      const el = `<span>${_(s[0]).bold()} ${s[1]}</span><br>`;
      document.getElementById('report_stats_div').insertAdjacentHTML('beforeend', el);
    }
  }

  should_show_content() {
    return false;
  }

  get_date_from_current() {
    return this.date_from_ts(new Date(this.current.st_ctime * 1000));
  }
}

class OptionList extends Option {
  constructor(current, style, conf) {
    super(current, style, conf);
    this.show_option_list = true;
  }

  get_option_class() {
    return 'none';
  }

  justify_current() {
    return 'justify-self-stretch';
  }

  value_to_user(value) {
    var data = [];
    for (var i = 0; i < value.length; i++) {
      var d = {};
      if (this.style.header == 'String') {
        d['list'] = value[i];
      } else if (this.style.header == 'Float') {
        d['list'] = value[i].toFixed(2);
      } else {
        for (let e of value[i]) {
          d['list'] = e;
        }
      }
      data.push(d);
    }
    return data;
  }

  define_columns() {
    var columns = [
      {
        title: 'list',
        field: 'list',
        editable: false,
        minWidth: 100,
        frozen: false,
      },
    ];
    return columns;
  }

  render_list_table() {
    if (this.type === 'List') {
      if (this.handle !== 'curve') {
        this.do_table();
      } else {
        this.do_thermal_table();
      }
    }
  }

  do_table() {
    var parent = document.getElementById(`${this.handle}_list_table`);
    if (!parent) return;
    var t_opt = {
      height: '100%',
      layout: 'fitDataStretch',
      paginationSize: this.current.length,
      columns: this.define_columns(),
      headerVisible: false,
    };
    if (this.current.length > 5) {
      t_opt['pagination'] = 'local';
      t_opt['paginationButtonCount'] = 0;
    }
    t_opt.data = this.value_to_user(this.current);
    return new Tabulator(parent, t_opt);
  }

  do_thermal_table() {
    const parent = document.getElementById(`${this.handle}_list_table`);
    const data = [];
    const curve = this.current;
    const set_curve = setCurve(curve);
    for (let i = 0; i < curve.length; i++) {
      const R = set_curve[i][2].toFixed(1);
      const D = set_curve[i][3].toFixed(1);
      data.push({
        t: set_curve[i][0].toFixed(1),
        T: set_curve[i][1],
        R: R,
        D: D,
      });
    }
    return new Tabulator(parent, {
      data: data,
      responsiveLayout: 'collapse',
      layout: 'fitColumns',
      columns: [
        {
          title: _('Tot (min)'),
          field: 't',
        },
        {
          title: _('°C'),
          field: 'T',
        },
        {
          title: _('°C/min'),
          field: 'R',
        },
        {
          title: _('min'),
          field: 'D',
        },
      ],
    });
  }

  should_show_content() {
    return false;
  }

  fill_cell(ws, r) {
    if (this.handle === 'curve') {
      const curve = setCurve(this.current);
      const header_titles = ['Tot (min)', '°C', '°C/min', 'min'].map((h) => {
        return { v: h, s: export_header_style() };
      });

      const title_style = export_title_style();
      const title_data = [{ v: _(this.name), s: title_style }].concat(header_titles.map(() => ({ v: '', s: title_style })));

      XLSX.utils.sheet_add_aoa(ws, [title_data], { origin: 'A' + r });
      r = r + 1;
      XLSX.utils.sheet_add_aoa(ws, [header_titles], { origin: 'B' + r });
      r = r + 1;
      curve.forEach((row) => {
        XLSX.utils.sheet_add_aoa(ws, [[...row]], { origin: 'B' + r });
        r = r + 1;
      });
      return r;
    }
  }
}

globalThis.option_types['LogList'] = OptionLogList;
globalThis.option_types['Stat'] = OptionStat;
globalThis.option_types['List'] = OptionList;
