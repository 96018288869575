import { Option } from './option';
import { show_ephemeral_toast } from '../../common/toast';
import L from 'leaflet';

class OptionGps extends Option {
  constructor(current, style, conf) {
    super(current, style, conf);
  }

  should_show_content() {
    return false;
  }

  get_current_widget_id() {
    return `${this.uid}_gps_widget`;
  }

  add_map() {
    this.map?.eachLayer(function (layer) {
      layer.remove();
    });
    const map_options = {
      center: this.current,
      zoom: 16,
      zoomControl: false,
      boxZoom: false,
      doubleClickZoom: false,
      dragging: false,
      keyboard: false,
      scrollWheelZoom: false,
      layers: [
        new L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
          attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
        }),
      ],
    };
    this.map = L.map(`map_${this.handle}`, map_options);
    const tiles = document.getElementsByClassName('leaflet-tile');
    for (const tile of Object.values(tiles)) {
      tile.style.transform = 'none';
    }
  }

  template_input_edit_desc() {
    return [{ current_option_uuid: this.uuid }, 'option/edit_option_gps'];
  }

  async apply_changes(should_refresh_view = true) {
    this.map?.eachLayer(function (layer) {
      layer.remove();
    });
    let new_current = await this.current_from_user();
    if (Array.isArray(new_current)) {
      if (new_current != this.current) {
        this.set_new_current(new_current);
      }
    } else {
      show_ephemeral_toast(new_current, 'Error');
      return false;
    }

    should_refresh_view && this.refresh_view();
  }

  async value_from_user(value) {
    const coords = await fetch(`http://nominatim.openstreetmap.org/search?format=json&q=${value}`).then((r) =>
      r.json()
    );
    if (!coords || !coords.length) {
      return _('Location not found');
    }
    return [coords[0].lat, coords[0].lon];
  }
}

globalThis.option_types['GPS'] = OptionGps;
