import { SplitConf } from './split_conf';
import { _ } from '../common/translations';
import { show_sticky_toast } from '../common/toast';

class Formulation extends SplitConf {
  // override per separare la vista in due quando la tabella non è visibile
  formulation_toast_title(doc) {
    let title = 'Info';
    if (!doc.success) title = 'Error';
    if (doc.success && doc.skipped.length) title = 'Warning';
    return title;
  }

  pop_from_local_storage() {
    const state = super.pop_from_local_storage();
    if (state['cmp_oxide']) {
      this.currents.target = state['cmp_oxide'];
    }
    const new_state = state['cmp_semi'] || state['cmp_mix'] || state['cmp_compound'];
    if (new_state) {
      this.currents.materials = new_state.map((row) => [row[0], 0, 100, 0]);
    }

    if (state['materials']) {
      this.currents.materials = state['materials'];
    }

    const that = this;
    ['target', 'operation', 'objective', 'tolerance', 'minTolerance', 'equality'].forEach((field) => {
      if (state[field] !== undefined && state[field] !== null) {
        that.currents[field] = state[field];
      }
    });

    return state;
  }

  formulation_toast_message(doc) {
    let message = '';
    if (!doc.success) {
      message = `${_('Optimization failed')} <br> ${_(doc.message)}`;
    } else {
      if (doc.skipped.length) {
        message = _('target-skipped');
      } else {
        message = _('formulation-completed');
      }
    }
    return message;
  }

  execute_callback(resp) {
    console.log('Formulation callback');
    const doc = resp.doc;
    this.feedback_element = 'message_toast';
    this.feedback_title = this.formulation_toast_title(doc);
    this.feedback_message = this.formulation_toast_message(doc);
    super.execute_callback(resp, true);
    this.template_split_view();
  }

  show_formulation_toast() {
    if (this.executed === 0) {
      const title = this.formulation_toast_title(this.currents);
      const message = this.formulation_toast_message(this.currents);
      show_sticky_toast(message, title);
    }
  }
}

globalThis.material_classes ||= {};
globalThis.material_classes.Formulation = Formulation;

export {};
