import { rpc } from '../common/crystal_api/rpc';
import { create_genome_home_page, empty_element, populate_genome_search_form } from '../common/ui';

class SearchGenome extends Conf {
  constructor(currents, styles) {
    super(currents, styles);
    this.search_doc_classes = currents['classes'] || [];
    globalThis.addEventListener('compare-genome-search', () => {
      show_materials_table_ignoring_query_hash(
        { classes: globalThis.comparing_classes, nameComment: document.getElementById('search_in_home').value },
        false,
        'compare'
      );
    });
  }

  view(parent) {
    empty_element(parent);
    this.skip = ['name', 'comment', 'alternatives', 'results', 'limit', 'queryHash'];
    const options_descs = [];
    for (let handle of this.sections['main']) {
      if (this.options[handle].type === 'Section') {
        this.skip.push(handle);
      }
      if (!this.skip.includes(handle)) {
        const option_uuid = this.options[handle].create_template_option();
        options_descs.push(option_uuid);
      }
    }
    const genome_search_template = require('../view/document/document_search.handlebars');
    const genome_search_html = genome_search_template({
      option_uuid_list: JSON.stringify({ option_uuid_list: options_descs }),
      conf_uuid: JSON.stringify(this.uuid),
    });
    parent.insertAdjacentHTML('beforeend', genome_search_html);
  }

  is_search() {
    return true;
  }

  search_elements_created() {}

  clear_search_doc() {
    console.log('Clear search');
    document.getElementById('search_in_home').value = '';
    rpc
      .doc({
        uid: 'def:Style#SearchGenome#0',
      })
      .then((response) => create_genome_home_page(response, { force_initialization: true }))
      .then(() => globalThis.do_search());
  }

  do_search(should_reset_query_hash = true) {
    if (should_reset_query_hash) {
      reset_queryHash();
    }
    globalThis.do_search();
  }

  name_comment(option) {
    if (!option) option = this.options['nameComment'];
    if (option.input_edit) {
      option = option.input_edit;
    } else {
      option = document.getElementById(`${option.uid}_input`);
    }

    document.getElementById('search_in_home').value = option.value;
  }
}

function set_opt_inner_html(val) {
  if (val.startsWith('Material')) {
    return val.split('Material')[1];
  } else if (val.startsWith('Analysis')) {
    return val.split('Analysis')[1];
  } else if (val.startsWith('Report')) {
    return val.split('Report')[1];
  } else if (val.startsWith('Model')) {
    return val.split('Model')[1];
  } else {
    return val;
  }
}

globalThis.material_classes ||= {};
globalThis.material_classes.SearchGenome = SearchGenome;

export { set_opt_inner_html, SearchGenome };
